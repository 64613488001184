import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import Swal from 'sweetalert2';
import Typography from '@mui/material/Typography'
import { useConfirm } from 'material-ui-confirm'
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Badge,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  FormControlLabel,
  Switch,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  authActions,
  empresaAction,
  usuarioAction,
  orgaoAction,
  cidadeAction,
  estadoAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, cnpjMask, stringUtils, phoneMask } from '../../utils'
import { Page } from 'components'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ButtonAdd = styled(Button)({
  backgroundColor: '#F2D647',
  marginLeft: 7,
  marginRight: 7,
  marginBottom: 3,
  '&:hover': {
    backgroundColor: '#F2D647',
    borderColor: '#F2D647',
  },
})

const BadgeShowNames = styled(Badge)({
  fontSize: 15,
  paddingLeft: 7,
  paddingRight: 7,
  color: '#000'
})

const EmpresaEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, empresa, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const confirm = useConfirm()

  const [idUsuario, setIdUsuario] = useState(0);
  const [textUsuario, setTextUsuario] = useState('');

  const [idOrgao, setIdOrgao] = useState(0);
  const [textOrgao, setTextOrgao] = useState('');

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false)

  const [valueOrgao, setValueOrgao] = React.useState(null);
  const [inputValueOrgao, setInputValueOrgao] = React.useState('');
  const [openOrgao, setOpenOrgao] = React.useState(false)

  const usuarios = useSelector((state) => state.usuario.usuario)

  const orgaos = useSelector((state) => state.orgao.orgao)

  const { cidade } = useSelector(
    (state) => state.cidade
  )

  const estados = useSelector((state) => state.estado.estado)

  const options = cidade.map(u => ({ id: u.value, nome: u.text }));

  const optionsOrgaos = orgaos.map(u => ({ id: u.value, nome: u.text }));

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    dispatch(empresaAction.clearUsuariosEmpresas())
    dispatch(usuarioAction.getUsuarioSelect())
    dispatch(orgaoAction.getOrgaoSelect())
    dispatch(estadoAction.getEstadoSelect())
    if (params.id) {
      dispatch(empresaAction.getEmpresaById(params.id))
    } else {
      dispatch(empresaAction.clear())
      dispatch(empresaAction.clearUsuariosEmpresas())
    }


    if (props.empresa.idEstado != undefined && props.empresa.idEstado !== 0 && props.empresa.idEstado !== '') {
      dispatch(cidadeAction.getCidadeByIdEstado(props.empresa.idEstado))
    }

    const interval = setInterval(() => {
      setReady(true)
    }, 1500);
    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    if (props.empresa.idEstado) {
      dispatch(cidadeAction.getCidadeByIdEstado(props.empresa.idEstado))
    }
  }, [props.empresa.idEstado])

  const handleAddOrgaoOnList = () => {
    if (props.empresa.empresasOrgaos.filter(ts => ts.idOrgao == parseInt(idOrgao)).length > 0) {
      toast.error('Erro ao adicionar! Este órgao já existe!')
    }
    else {
      props.empresa.empresasOrgaos.push({
        'idOrgao': idOrgao,
        'idEmpresa': props.empresa.id,
        'orgao': {
          'nome': textOrgao
        }
      });
      forceUpdate(n => !n);
      setIdOrgao(0);
      setValueOrgao(null);
    }
  }

  const handleRemoveOrgaoOnList = idOrgao => {
    props.empresa.empresasOrgaos = props.empresa.empresasOrgaos.filter(ts => ts.idOrgao != parseInt(idOrgao));
    forceUpdate(n => !n);
  };

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selected = cidade.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        cidadeAction.changeDetailsCidade(
          selected[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  const handleChangeAutoCompleteOrgao = (obj) => {
    if (obj != null) {
      setIdOrgao(obj.id);
      setTextOrgao(obj.nome);
    }
    setOpenOrgao(false)
  }


  useEffect(() => {
    if (valueOrgao) {
      handleChangeAutoCompleteOrgao(valueOrgao)
    }
  }, [valueOrgao])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.empresasOrgaos])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.usuariosEmpresas])

  useEffect(() => {

  }, [ready, props.empresa.isLoading])

  const handleSelectChangeState = (prop) => (event) => {
    setIdUsuario(event.target.value);
    setTextUsuario(event.nativeEvent.target.innerText);
  };

  const handleChange = (prop) => (event) => {
    dispatch(empresaAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      razaoSocial: props.empresa.razaoSocial,
      nomeFantasia: props.empresa.nomeFantasia,
      cnpj: props.empresa.cnpj ? props.empresa.cnpj.split('.').join('').replace(/[-/.]/g, '') : '',
      celular: props.empresa.celular ? props.empresa.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : '',
      email: props.empresa.email,
      telefone: props.empresa.telefone ? props.empresa.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : '',
      endereco: props.empresa.endereco,
      inscricaoEstadual: props.empresa.inscricaoEstadual,
      idCidade: value ? value.id : props.empresa.idCidade,
      idEstado: props.empresa.idEstado,
      usuariosEmpresas: props.empresa.usuariosEmpresas,
      empresasOrgaos: props.empresa.empresasOrgaos,
      fornecedor: props.empresa.fornecedor,
      prestadorServico: props.empresa.prestadorServico
    }

    if (params.id) {
      dispatch(empresaAction.editEmpresaInfo(params.id, payload, true))
    } else {
      dispatch(empresaAction.createEmpresa(payload))
    }

  };

  const handleAprovaCadastroEmpresa = () => {
    confirm({
      title: 'Você deseja liberar o acesso ao sistema para esta Empresa?',
      description: 'Essa operação é irreversível',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Aprovando...' : 'Sim, aprovar!',
    }).then(() => {
      dispatch(empresaAction.editAprovaCadastroEmpresa(params.id))
    })
  }

  const handleRecusaCadastroEmpresa = () => {
    confirm({
      title: 'Você deseja recusar o acesso ao sistema para esta Empresa?',
      description: 'Essa operação é irreversível',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Recusando...' : 'Sim, recusar!',
    }).then(() => {
      dispatch(empresaAction.editRecusaCadastroEmpresa(params.id))
    })
  }

  const handleAddOnList = e => {

    if (props.empresa.usuariosEmpresas.filter(ts => ts.idUsuario == parseInt(idUsuario)).length > 0) {
      toast.error('Erro ao adicionar! Este usuário já existe!')
    }
    else {

      if (idUsuario != '' && idUsuario != '0') {
        props.empresa.usuariosEmpresas.push({ 'idUsuario': idUsuario, 'idEmpresa': props.empresa.id, 'usuario': { 'nome': textUsuario } });
        forceUpdate(n => !n);
        setIdUsuario(0);

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos um usuário!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {
    props.empresa.usuariosEmpresas = props.empresa.usuariosEmpresas.filter(ts => ts.idUsuario != parseInt(id));
    forceUpdate(n => !n);
  };

  function excludeFilter(obj) {
    if (props.empresa.usuariosEmpresas && props.empresa.usuariosEmpresas.length > 0) {
      if (props.empresa.usuariosEmpresas.filter(fl => fl.idUsuario == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Empresa'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Empresa'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Empresas - Editar Empresa'
          : 'Gerência de Empresas - Nova Empresa'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formEmpresa"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Empresas"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="razaoSocial"
                        label="Razão Social *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('razaoSocial')}
                        value={props.empresa.razaoSocial || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nomeFantasia"
                        label="Nome Fantasia *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nomeFantasia')}
                        value={props.empresa.nomeFantasia || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cnpj"
                        label="CNPJ *"
                        margin="normal"
                        onChange={handleChange('cnpj')}
                        value={cnpjMask(props.empresa.cnpj) || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="number"
                        id="ie"
                        inputProps={{ maxLength: 12 }}
                        label="Inscrição Estadual"
                        margin="normal"
                        onChange={handleChange('inscricaoEstadual')}
                        value={props.empresa.inscricaoEstadual || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="E-mail *"
                        value={props.empresa.email || ''}
                        className={classes.textField}
                        onChange={handleChange('email')}
                        id="email"
                        name="email"
                        autoComplete="email"
                        validators={['required', 'isEmail']}
                        errorMessages={[
                          'Você precisa informar o e-mail',
                          'E-mail inválido'
                        ]}
                        disabled={props.empresa.id != 0}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="telefone"
                        label="Telefone *"
                        margin="normal"
                        onChange={handleChange('telefone')}
                        value={phoneMask(props.empresa.telefone) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="celular"
                        label="Celular *"
                        margin="normal"
                        onChange={handleChange('celular')}
                        value={phoneMask(props.empresa.celular) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        variant="outlined"
                        id="idEstado"
                        label="Estado"
                        value={props.empresa.idEstado}
                        onChange={handleChange('idEstado')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idEstado"
                      >
                        <MenuItem disabled value="">
                          <em>Estado</em>
                        </MenuItem>
                        {!isEmpty(estados) &&
                          undefined !== estados &&
                          estados.length &&
                          estados.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingTop: 31 }}>
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        margin="normal"
                        variant="outlined"
                        defaultValue={null}
                        getOptionLabel={option => option.nome || ''}
                        renderInput={(params) => <TextField {...params} label="Selecione uma cidade..." fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="endereco"
                        label="Endereço *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('endereco')}
                        value={props.empresa.endereco || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <CardContent style={{ placeItems: 'center' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.empresa.fornecedor}
                              onChange={handleChange('fornecedor')}
                              color="default"
                            />
                          }
                          label="Fornecedor"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              color="default"
                              checked={props.empresa.prestadorServico}
                              onChange={handleChange('prestadorServico')}
                            />
                          }
                          label="Prestador de Serviço"
                        />
                      </CardContent>
                    </Grid>
                  </Grid>
                </CardContent>
                <Grid item md={12} xs={12} style={{ marginTop: 15, marginBottom: 25 }}>
                  <Divider />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography component="h4" variant="subtitle" align="center">
                      Usuários vinculados a empresa
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: 20 }} >
                    <SelectValidator
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      variant="outlined"
                      id="idUsuario"
                      label="Selecione um Usuário *"
                      value={idUsuario}
                      onChange={handleSelectChangeState('idUsuario')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      displayEmpty
                      labelId="idUsuario"
                    >
                      <MenuItem disabled value="" key={12}>
                        <em>Usuário *</em>
                      </MenuItem>
                      {!isEmpty(usuarios) &&
                        undefined !== usuarios &&
                        usuarios.length &&
                        usuarios.filter(excludeFilter).map((row, index) => (
                          <MenuItem key={'u' + index} value={row.value}>
                            {row.text}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                    <Button style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }} name="btnUsuarios" id="btnUsuarios" variant="contained" className="btn" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                  </Grid>
                  <Grid item lg={5} md={5} xl={5} xs={12}>
                    <div className="selectgroup selectgroup-pills" style={{ marginLeft: 20, alignItems: 'top' }}>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        {
                          (props.empresa.usuariosEmpresas != null && props.empresa.usuariosEmpresas.length > 0 ?
                            props.empresa.usuariosEmpresas.map(n => {
                              return (
                                (n.id !== '' ?
                                  <ButtonAdd onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                    <BadgeShowNames>
                                      {(n.usuario ? n.usuario.nome : n.idUsuario)}
                                    </BadgeShowNames>
                                    &nbsp;<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000' }} />
                                  </ButtonAdd>
                                  : null)
                              );
                            })
                            : null)
                        }
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: 15, marginBottom: 25 }}>
                  <Divider />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography component="h4" variant="subtitle" align="center">
                      Cidades de Interesse
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: 20 }} >
                    <Autocomplete
                      value={valueOrgao}
                      onChange={(event, newValueOrgao) => {
                        setValueOrgao(newValueOrgao);
                      }}
                      inputValue={inputValueOrgao}
                      onInputChange={(event, newInputValueOrgao) => {
                        setInputValueOrgao(newInputValueOrgao);
                      }}
                      id="controllable-states-demo"
                      options={optionsOrgaos}
                      defaultValue={null}
                      getOptionLabel={option => option.nome}
                      renderInput={(params) => <TextValidator {...params} label="Selecione uma cidade ..."
                        variant="outlined" fullWidth />}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                    <Button style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }} name="btnOrgaos" id="btnOrgaos" variant="contained" className="btn" component='a' onClick={handleAddOrgaoOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                  </Grid>
                  <Grid item lg={5} md={5} xl={5} xs={12}>
                    <div className="selectgroup selectgroup-pills" style={{ marginLeft: 20, alignItems: 'top' }}>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        {
                          (props.empresa.empresasOrgaos != null && props.empresa.empresasOrgaos.length > 0 ?
                            props.empresa.empresasOrgaos.map(n => {
                              return (
                                (n.id !== '' ?
                                  <ButtonAdd onClick={() => handleRemoveOrgaoOnList(n.idOrgao)} key={'button' + n.id}>
                                    <BadgeShowNames>
                                      {(n.orgao ? n.orgao.nome : n.idOrgao)}
                                    </BadgeShowNames>
                                    &nbsp;<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000' }} />
                                  </ButtonAdd>
                                  : null)
                              );
                            })
                            : null)
                        }
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      variant="outlined"
                      style={{ color: '#000', borderColor: '#000' }}
                      onClick={() => history.push('/empresas')}
                    >
                      Cancelar
                    </Button>
                    {empresa.id && (authActions.isPrefeitura() || authActions.isGestor()) ?
                      <>
                        <Button
                          variant="outlined"
                          style={{ color: '#000', borderColor: '#000' }}
                          onClick={handleRecusaCadastroEmpresa}
                          disabled={props.empresa.cadastroLiberado == false || props.empresa.isLoading}
                          startIcon={<CloseIcon />}
                          endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                        >
                          {props.empresa.cadastroLiberado == false ? 'Acesso recusado' : props.empresa.isLoading ? 'Recusando acesso...' : 'Recusar acesso'}
                        </Button>
                        <Button
                          variant="outlined"
                          style={{ color: '#000', borderColor: '#000' }}
                          onClick={handleAprovaCadastroEmpresa}
                          disabled={props.empresa.cadastroLiberado == true || props.empresa.isLoading}
                          startIcon={<DoneIcon />}
                          endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                        >
                          {props.empresa.cadastroLiberado == true ? 'Acesso liberado' : props.empresa.isLoading ? 'Liberando acesso...' : 'Liberar acesso'}
                        </Button>
                      </>
                      : null}
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: '#000' }}
                      disabled={props.empresa.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.empresa.isLoading ? 'Atualizando empresa...' : 'Atualizar') : (props.empresa.isLoading ? 'Salvando empresa...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page >
  )
}

EmpresaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedEmpresaEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(EmpresaEdit))
)
export { connectedEmpresaEditPage as EmpresaEdit }
