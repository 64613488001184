import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import Swal from 'sweetalert2';
import Typography from '@mui/material/Typography'
import {
  Avatar,
  Backdrop,
  Badge,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Grid,
  MenuItem,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  veiculoAction,
  usuarioAction,
  orgaoAction,
  authActions,
  marcaAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { uploadImage } from '_services'
import { useConfirm } from 'material-ui-confirm'
import ImageUploading from 'react-images-uploading'
import { errorsUploadImage } from 'utils'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';
import getInitials from 'utils/getInitials'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(1),
    justifyContent: 'space-between'
  },
  avatar: {
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer',
    objectFit: 'cover'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const VeiculoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, veiculo, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idUsuario, setIdUsuario] = useState(0);

  const confirm = useConfirm()
  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)

  const [textUsuario, setTextUsuario] = useState('');

  const usuarios = useSelector((state) => state.usuario.usuario)
  const orgaos = useSelector((state) => state.orgao.orgao)
  const marcas = useSelector((state) => state.marca.marca)

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...veiculo,
        caminhoFoto: null
      }
      dispatch(
        veiculoAction.editVeiculoInfo(veiculo.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(veiculoAction.onChangeProps('caminhoFoto', event2))
      setImagem(null)
    })
  }

  const onChangeImage = (imageList) => {
    //console.log("imageList", imageList)
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...veiculo,
          caminhoFoto: response.toString()
        }

        if (veiculo.id !== 0) {
          dispatch(
            veiculoAction.editVeiculoInfo(veiculo.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(veiculoAction.onChangeProps('caminhoFoto', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {
    setImagem(veiculo.caminhoFoto)
  }, [veiculo.caminhoFoto, open]);

  useEffect(() => {
    // forceUpdate(n => !n);
  }, [open, imagem]);

  useEffect(() => {

    dispatch(marcaAction.getMarcaSelect())
    dispatch(orgaoAction.getOrgaoSelect())

    if (params.id) {
      dispatch(veiculoAction.getVeiculoById(params.id))
    } else {
      dispatch(veiculoAction.clear())
    }

  }, []);

  useEffect(() => {
    if (idOrgao != 0) {
      dispatch(usuarioAction.getUsuarioMotoristaByOrgaoSelect(idOrgao))
    } else {
      toast.error('Selecione um órgão');
    }
  }, [idOrgao]);

  useEffect(() => {
  }, [ready, props.veiculo.isLoading])

  const handleSelectChangeState = (prop) => (event) => {
    setIdUsuario(event.target.value);
    setTextUsuario(event.nativeEvent.target.innerText);
  };

  const handleChange = (prop) => (event) => {
    dispatch(veiculoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      ano: props.veiculo.ano,
      placa: props.veiculo.placa,
      renavam: props.veiculo.renavam,
      hodometro: props.veiculo.hodometro,
      numeroChassis: props.veiculo.numeroChassis,
      idMarca: props.veiculo.idMarca,
      modelo: props.veiculo.modelo,
      caminhoFoto: props.veiculo.caminhoFoto,
      idOrgao: idOrgao != 0 ? idOrgao : props.veiculo.idOrgao,
      usuariosVeiculos: props.veiculo.usuariosVeiculos
    }

    if (params.id) {
      dispatch(veiculoAction.editVeiculoInfo(params.id, payload, true))
    } else {
      dispatch(veiculoAction.createVeiculo(payload))
    }

  };

  const handleAddOnList = e => {

    if (props.veiculo.usuariosVeiculos.filter(ts => ts.idUsuario == parseInt(idUsuario)).length > 0) {
      toast.error('Erro ao adicionar! Este usuário já existe!')
    }
    else {

      if (idUsuario != '' && idUsuario != '0') {
        props.veiculo.usuariosVeiculos.push({ 'idUsuario': idUsuario, 'idVeiculo': props.veiculo.id, 'usuario': { 'nome': textUsuario } });
        forceUpdate(n => !n);
        setIdUsuario(0);

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos um usuário!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {
    props.veiculo.usuariosVeiculos = props.veiculo.usuariosVeiculos.filter(ts => ts.idUsuario != parseInt(id));
    forceUpdate(n => !n);
  };

  function excludeFilter(obj) {
    if (props.veiculo.usuariosVeiculos && props.veiculo.usuariosVeiculos.length > 0) {
      if (props.veiculo.usuariosVeiculos.filter(fl => fl.idUsuario == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Veículo'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Veículo'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Veículos - Editar Veículo'
          : 'Gerência de Veículos - Novo Veículo'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando foto...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploading
              maxFileSize={500000}
              onError={(errors) => errorsUploadImage(errors)}
              value={images}
              acceptType={['jpeg', 'jpg']}
              onChange={onChangeImage}
              resolutionWidth={1100}
              resolutionHeight={1100}
              resolutionType={"less"}
              dataURLKey="data_url"
            >
              {({ onImageUpload, isDragging, dragProps }) => (
                <Card {...rest} className={clsx(classes.root, className)}>
                  <CardContent {...dragProps} className={classes.content}>
                    {isDragging ? (
                      <>
                        <CloudUploadIcon
                          sx={{ fontSize: 40 }}
                        />
                        <Typography variant="subtitle2">
                          Solte a imagem aqui!
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Tooltip
                          title={
                            veiculo != null &&
                              imagem != null &&
                              imagem != ''
                              ? 'Clique para trocar a foto!'
                              : 'Clique para adicionar uma foto!'
                          }
                        >
                          <Avatar
                            sx={{ width: '100%', height: 160 }}
                            key={(imagem != '' && imagem != null ? imagem.substr(imagem.lastIndexOf('/'), imagem.length - imagem.lastIndexOf('/')) : '')}
                            onClick={onImageUpload}
                            variant="rounded"
                            //remover cache = new Date().getTime()} 
                            src={imagem}
                          >
                            {veiculo.placa}
                          </Avatar>
                        </Tooltip>
                        <Typography
                          align="center"
                          className={classes.nome}
                          gutterBottom
                          variant="h3"
                        >

                        </Typography>
                      </>
                    )}
                  </CardContent>
                  <Divider />
                  <CardActions className={classes.uploadButton} align="center">
                    <Button variant="outlined" style={{ color: '#000', borderColor: '#000', justifyContent: 'center' }} onClick={onImageUpload}>
                      {veiculo != null &&
                        imagem != null &&
                        imagem != ''
                        ? 'Trocar foto'
                        : 'Carregar foto'}
                    </Button>
                    {veiculo != null &&
                      imagem != null &&
                      imagem != '' && (
                        <Button variant="outlined" style={{ color: '#000', borderColor: '#000', justifyContent: 'center' }} onClick={removeImage}>
                          Remover foto
                        </Button>
                      )}
                  </CardActions>
                </Card>
              )}
            </ImageUploading>
          </Grid>
        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formVeiculo"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Veículos"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="ano"
                        type="number"
                        label="Ano *"
                        margin="normal"
                        onChange={handleChange('ano')}
                        value={props.veiculo.ano || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="placa"
                        label="Placa *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('placa')}
                        value={props.veiculo.placa || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="hodometro"
                        type="number"
                        label="Hodômetro"
                        margin="normal"
                        onChange={handleChange('hodometro')}
                        value={props.veiculo.hodometro || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        variant="outlined"
                        id="idMarca"
                        onChange={handleChange('idMarca')}
                        label="Marca"
                        value={props.veiculo.idMarca}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idMarca"
                      >
                        <MenuItem disabled value="">
                          <em>Marca</em>
                        </MenuItem>
                        {!isEmpty(marcas) &&
                          undefined !== marcas &&
                          marcas.length &&
                          marcas.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="modelo"
                        label="Modelo"
                        margin="normal"
                        onChange={handleChange('modelo')}
                        value={props.veiculo.modelo || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="renavam"
                        label="Renavam *"
                        margin="normal"
                        onChange={handleChange('renavam')}
                        value={props.veiculo.renavam || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="numeroChassis"
                        type="number"
                        label="Número Chassis *"
                        margin="normal"
                        onChange={handleChange('numeroChassis')}
                        value={props.veiculo.numeroChassis || ''}
                        variant="outlined"
                      />
                    </Grid>
                    {idOrgao != null && idOrgao != 0 ?
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <SelectValidator
                          className={classes.textField}
                          variant="outlined"
                          id="idOrgao"
                          label="Órgão *"
                          value={idOrgao}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          disabled
                          labelId="idOrgao"
                        >
                          <MenuItem disabled value="">
                            <em>Órgão *</em>
                          </MenuItem>
                          {!isEmpty(orgaos) &&
                            undefined !== orgaos &&
                            orgaos.length &&
                            orgaos.map((row, index) => (
                              <MenuItem key={index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      :
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <SelectValidator
                          className={classes.textField}
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          id="idOrgao"
                          label="Órgão *"
                          value={props.veiculo.idOrgao}
                          onChange={handleChange('idOrgao')}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="idOrgao"
                        >
                          <MenuItem disabled value="">
                            <em>Órgão *</em>
                          </MenuItem>
                          {!isEmpty(orgaos) &&
                            undefined !== orgaos &&
                            orgaos.length &&
                            orgaos.map((row, index) => (
                              <MenuItem key={index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                    }
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid container lg={12} md={12} xl={12} xs={12} spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Motoristas vinculados ao veículo
                      </Typography>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: 6 }}>
                      <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: 20 }} >
                        <SelectValidator
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          id="idUsuario"
                          label="Usuários *"
                          value={idUsuario}
                          onChange={handleSelectChangeState('idUsuario')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="idUsuario"
                        >
                          <MenuItem disabled value="" key={12}>
                            <em>Usuário *</em>
                          </MenuItem>
                          {!isEmpty(usuarios) &&
                            undefined !== usuarios &&
                            usuarios.length &&
                            usuarios.filter(excludeFilter).map((row, index) => (
                              <MenuItem key={'u' + index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                        <Button style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }} name="btnUsuarios" id="btnUsuarios" variant="contained" className="btn" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <div className="selectgroup selectgroup-pills" style={{ marginLeft: 20, alignItems: 'top' }}>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            {
                              (props.veiculo.usuariosVeiculos != null && props.veiculo.usuariosVeiculos.length > 0 ?
                                props.veiculo.usuariosVeiculos.map(n => {
                                  return (
                                    (n.id !== '' ?
                                      <Button style={{ fontWeigth: 400, backgroundColor: '#F2D647', marginLeft: 7, marginRight: 7 }} onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                        <Badge style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7, color: '#000' }}>
                                          {(n.usuario ? n.usuario.nome : n.idUsuario)}
                                        </Badge>
                                        &nbsp;<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000' }} />
                                      </Button>
                                      : null)
                                  );
                                })
                                : null)
                            }
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      variant="outlined"
                      style={{ color: '#000', borderColor: '#000' }}
                      onClick={() => history.push('/veiculos')}
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: '#000' }}
                      disabled={props.veiculo.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.veiculo.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.veiculo.isLoading ? 'Atualizando veículo...' : 'Atualizar') : (props.veiculo.isLoading ? 'Salvando veículo...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page >
  )
}

VeiculoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedVeiculoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(VeiculoEdit))
)
export { connectedVeiculoEditPage as VeiculoEdit }
