import React, { useEffect } from 'react';
import clsx from 'clsx'
import Card from '@mui/material/Card';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Avatar, Typography, CardContent, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardAction } from '_actions'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    marginRight: 10,
    minHeight: 120,
    minWidth: 300
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main
  }
}));

const CardHorasRecebimentoOrcamentos = (props) => {
  const { className, ...rest } = props
  const classes = useStyles();
  const dispatch = useDispatch();

  const horasRecebimentoOrcamentos = useSelector((state) => (state.dashboard ? state.dashboard.dashboard.horasRecebimentoOrcamentos : undefined))

  useEffect(() => {
    dispatch(dashboardAction.getDashboard());

    console.log("horasRecebimentoOrcamentos", horasRecebimentoOrcamentos)
  }, [])

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item styles={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item sx={{ display: 'flex', flexDirection: 'row', alignContent: 'end' }}>
              <Typography variant="h2" className={classes.title}>
                {horasRecebimentoOrcamentos ? horasRecebimentoOrcamentos : ''}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                Horas para receber orçamentos
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Avatar sx={{ width: 65, height: 65, bgcolor: '#F2D647' }}>
              <AccessTimeIcon sx={{ fontSize: 35, color: '#000' }} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
}

CardHorasRecebimentoOrcamentos.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string
}

export default CardHorasRecebimentoOrcamentos
