import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UsuarioEdit } from '../../views/Usuarios'
import {
  Grid,
  Button,
  Typography
} from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { AccessControl } from '../../components/Utils/AccessControl'
import { useConfirm } from 'material-ui-confirm'

const styles = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const UsuarioEditModal = ({ modal, toggle, props, idorgao, isorgao }) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, orgao, className, ...rest } = props
  const { match: { params } } = props;

  const { idUsuario } = useSelector(
    (state) => state.usuario
  )

  const isLoading = useSelector(
    (state) => state.usuarioOrgao.isLoading
  )

  useEffect(() => {
  }, [props.orgao.isLoading, isLoading])

  const handleClose = () => {
    toggle();
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      maxWidth={true}
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle style={{ paddingBottom: 0, marginBottom: 0 }}>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={1} style={{ padding: 0, margin: 0 }}>
          <AccessControl
            rule={'usuariosOrgaos.create'} //permissão necessária para acessar conteúdo
            yes={() => (
              <Grid item lg={12} md={12} xl={12} xs={12} align="right">
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  endIcon={<CloseIcon />}
                  onClick={() => handleClose()}
                >
                  Fechar
                </Button>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <UsuarioEdit idorgao={idorgao} toggle={toggle} idUsuario={idUsuario} isorgao={isorgao} />
      </DialogContent>
    </Dialog >
  )
}
