import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { orgaoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';


const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  }
})

const Orgao = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { orgao } = props.orgao
  const { totalRegistros } = props.orgao

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [status, setStatus] = useState('');

  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const buttons = [
    <Button sx={{ color: '#000', borderColor: '#FFC107' }} onClick={() => setStatus('P')}>Pendentes</Button>,
    <Button sx={{ color: '#000', borderColor: '#FFC107' }} onClick={() => setStatus('A')}>Aprovados</Button>,
    <Button sx={{ color: '#000', borderColor: '#FFC107' }} onClick={() => setStatus('R')}>Recusados</Button>,
    <Button sx={{ color: '#000', borderColor: '#FFC107' }} onClick={() => setStatus('')}>Todos</Button>
  ];

  useEffect(() => {
    setStatus('')
  }, [])

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: status
      };

      dispatch(orgaoAction.getOrgao(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);
  }, [page, limit, debounceTerm, order, direction, idOrgao]);

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: status
      };
      dispatch(orgaoAction.getOrgao(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [status])

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.orgao.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este órgão?',
      description: 'Essa operação é irreversível',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Excluindo órgão...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(orgaoAction.deleteOrgaoById(id))
    })
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Orgaos">
      <AccessControl
        rule={'orgaos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== orgao && orgao.length > 0 ? (
              <TitleContent
                rule={'orgaos.create'}
                length={orgao.length}
                subTitle={'GERÊNCIA DE ÓRGÃO'}
                title={'Lista de Órgãos'}
                href={'/orgao'}
              />
            ) : (
              <TitleContent
                rule={'orgaos.create'}
                length={0}
                subTitle={'GERÊNCIA DE ÓRGÃO'}
                title={'Lista de Órgãos'}
                href={'/orgao'}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              <Grid item xl={9} lg={9} md={9} xs={12} align="right">
                <FormControl>
                  <FormLabel>
                    <Typography variant="subtitle1">Filtrar por Status </Typography>
                  </FormLabel>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      '& > *': {
                        m: 1,
                      },
                    }}
                  >
                    <ButtonGroup size="medium" variant="outlined" aria-label="large button group">
                      {buttons}
                    </ButtonGroup>
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== orgao && orgao.length
                    ? orgao.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.nome ? n.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.cadastroLiberado == true ? 'CADASTRO LIBERADO' :
                              n.cadastroLiberado == false ? 'CADASTRO RECUSADO' :
                                n.cadastroLiberado == null && 'PENDENTE'}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'orgaos.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/orgao/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'orgaos.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
Orgao.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    orgao: state.orgao
  }
}
const connectedOrgaoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Orgao))
)
export { connectedOrgaoPage as Orgao }
