const initialState = {
  totalRegistros: 0,
  isLoading: false,
  ordemServico: [],
  open: false,
  id: '',
  descritivo: '',
  titulo: '',
  idTipoServico: 0,
  idVeiculo: 0,
  idUsuario: 0,
  data: '',
  avaliacao: 0,
  descritivoAvaliacao: '',
  idUsuarioAvaliacao: 0,
  idOrgao: 0,
  idEmpresa: 0,
  ultimoEvento: '',
  orgao: [],
  empresa: [],
  eventos: [],
  ordensServicosFotos: [],
  ordensServicosOrcamentos: []
}

export function ordemServico(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ORDEMSERVICO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_ORDEMSERVICO_SUCCESS':
      return {
        ...state,
        id: action.ordemServico.id,
        isLoading: false,
        idTipoServico: action.ordemServico.idTipoServico,
        descritivo: action.ordemServico.descritivo,
        titulo: action.ordemServico.titulo,
        idVeiculo: action.ordemServico.idVeiculo,
        idUsuario: action.ordemServico.idUsuario,
        data: action.ordemServico.data,
        avaliacao: action.ordemServico.avaliacao,
        descritivoAvaliacao: action.ordemServico.descritivoAvaliacao,
        idUsuarioAvaliacao: action.ordemServico.idUsuarioAvaliacao,
        idOrgao: action.ordemServico.idOrgao,
        idEmpresa: action.ordemServico.idEmpresa,
        ultimoEvento: action.ordemServico.ultimoEvento,
        orgao: action.ordemServico.orgao,
        empresa: action.ordemServico.empresa,
        eventos: action.ordemServico.eventos,
        ordensServicosFotos: action.ordemServico.ordensServicosFotos,
        ordensServicosOrcamentos: action.ordemServico.ordensServicosOrcamentos
      }
    case 'CREATE_ORDEMSERVICO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_ORDEMSERVICO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_ORDEMSERVICO_SUCCESS':
      return {
        ...state,
        id: action.ordemServico.id,
        isLoading: false,
        idTipoServico: action.ordemServico.idTipoServico,
        descritivo: action.ordemServico.descritivo,
        titulo: action.ordemServico.titulo,
        idVeiculo: action.ordemServico.idVeiculo,
        idUsuario: action.ordemServico.idUsuario,
        data: action.ordemServico.data,
        avaliacao: action.ordemServico.avaliacao,
        descritivoAvaliacao: action.ordemServico.descritivoAvaliacao,
        idUsuarioAvaliacao: action.ordemServico.idUsuarioAvaliacao,
        idOrgao: action.ordemServico.idOrgao,
        idEmpresa: action.ordemServico.idEmpresa,
        ultimoEvento: action.ordemServico.ultimoEvento,
        orgao: action.ordemServico.orgao,
        empresa: action.ordemServico.empresa,
        eventos: action.ordemServico.eventos,
        ordensServicosFotos: action.ordemServico.ordensServicosFotos,
        ordensServicosOrcamentos: action.ordemServico.ordensServicosOrcamentos
      }
    case 'EDIT_ORDEMSERVICO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_ORDEMSERVICO':
      return {
        ...state,
        ordemServico: action.ordemServico,
        totalRegistros: action.totalRegistros
      }
    case 'ORDEMSERVICO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        idTipoServico: action.idTipoServico,
        descritivo: action.descritivo,
        titulo: action.titulo,
        idVeiculo: action.idVeiculo,
        idUsuario: action.idUsuario,
        data: action.data,
        avaliacao: action.avaliacao,
        descritivoAvaliacao: action.descritivoAvaliacao,
        idUsuarioAvaliacao: action.idUsuarioAvaliacao,
        idOrgao: action.idOrgao,
        idEmpresa: action.idEmpresa,
        ultimoEvento: action.ultimoEvento,
        orgao: action.orgao,
        empresa: action.empresa,
        eventos: action.eventos,
        ordensServicosFotos: action.ordensServicosFotos,
        ordensServicosOrcamentos: action.ordensServicosOrcamentos
      }
    case 'ORDEMSERVICO_CLEAR':
      return initialState
    case 'ORDEMSERVICO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        ordemServico: [...state.ordemServico, action.data.ordemServico]
      }
    case 'ORDEMSERVICO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ORDEMSERVICO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ORDEMSERVICO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
