const initialState = {
  totalRegistros: 0,
  isLoading: false,
  ordemServicoFoto: [],
  open: false,
  id: '',
  idTipoFoto: 0,
  caminhoFoto: '',
  idOrdemServico: 0,
  excluido: false
}

export function ordemServicoFoto(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ORDEMSERVICO_FOTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_ORDEMSERVICO_FOTO_SUCCESS':
      return {
        ...state,
        id: action.ordemServicoFoto.id,
        isLoading: false,
        idTipoFoto: action.ordemServicoFoto.idTipoFoto,
        caminhoFoto: action.ordemServicoFoto.caminhoFoto,
        idOrdemServico: action.ordemServicoFoto.idOrdemServico,
        excluido: action.ordemServicoFoto.excluido,
        ordemServicoFoto: action.ordemServicoFoto.ordemServicoFoto
      }
    case 'CREATE_ORDEMSERVICO_FOTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_ORDEMSERVICO_FOTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_ORDEMSERVICO_FOTO_SUCCESS':
      return {
        ...state,
        id: action.ordemServicoFoto.id,
        isLoading: false,
        idTipoFoto: action.ordemServicoFoto.idTipoFoto,
        caminhoFoto: action.ordemServicoFoto.caminhoFoto,
        idOrdemServico: action.ordemServicoFoto.idOrdemServico,
        excluido: action.ordemServicoFoto.excluido,
        ordemServicoFoto: action.ordemServicoFoto.ordemServicoFoto
      }
    case 'EDIT_ORDEMSERVICO_FOTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_ORDEMSERVICO_FOTO':
      return {
        ...state,
        ordemServicoFoto: action.ordemServicoFoto,
        totalRegistros: action.totalRegistros
      }
    case 'ORDEMSERVICO_FOTO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        idTipoFoto: action.idTipoFoto,
        caminhoFoto: action.caminhoFoto,
        idOrdemServico: action.idOrdemServico,
        excluido: action.excluido,
        ordemServicoFoto: action.ordemServicoFoto
      }
    case 'ORDEMSERVICO_FOTO_CLEAR':
      return initialState
    case 'ORDEMSERVICO_FOTO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        ordemServicoFoto: [...state.ordemServicoFoto, action.data.ordemServicoFoto]
      }
    case 'ORDEMSERVICO_FOTO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ORDEMSERVICO_FOTO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ORDEMSERVICO_FOTO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
