import React from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { connect, useDispatch } from 'react-redux'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArticleIcon from '@mui/icons-material/Article';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';

import PropTypes from 'prop-types'
import {
  AppBar,
  Chip,
  Menu,
  Grid,
  MenuItem,
  Button,
  Toolbar,
  Hidden,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import { withStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu';
import { authActions } from '../../../../_actions'
import LogoutIcon from '@mui/icons-material/Logout';
import { withRouter } from 'react-router-dom'
import { history } from '../../../../utils'
import logo from '../../../../img/rotorWhite.png'
import { SelectOrgao } from './components'

const styles = (theme) => ({
  root: {
    boxShadow: 'none'
  },
  link: {
    color: "#FFF",
    font: theme.palette.body1,
    marginLeft: theme.spacing(2)
  },
  topBar: {
    color: 'inherit',
    textDecoration: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const Topbar = ({ staticContext, theme, ...props }) => {
  const { className, onSidebarOpen, ...rest } = props
  const { classes } = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const openUser = Boolean(anchorElUser);

  const dispatch = useDispatch()

  const user = useSelector((state) => state.authentication.user)

  const logout = () => {
    dispatch(authActions.logout())
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickUser = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUser = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      style={{ backgroundColor: '#25261C' }}
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar className={classes.topBar}>
        <Button
          style={{ marginRight: 22 }}
          id="basic-button1"
          onClick={() => history.push('/dashboard')}
        >
          <img
            width="60px"
            alt="Logo"
            src={logo}
          />
        </Button>
        <Button
          style={{ color: '#FFF', marginRight: 8 }}
          id="basic-button2"
          onClick={() => history.push('/dashboard')}
        >
          <HomeIcon />&nbsp;Home
        </Button>
        <Button
          style={{ color: '#FFF', marginRight: 8 }}
          id="basic-button3"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <AddCircleIcon />&nbsp;Cadastros
        </Button>
        <Menu id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            variant: 'menu'
          }}>
          {!authActions.isPrefeitura() &&
            <div>
              <MenuItem onClick={() => history.push('/empresas')}>
                <BusinessIcon />&nbsp;Empresas
              </MenuItem>
              <MenuItem onClick={() => history.push('/marcas')}>
                <BrandingWatermarkIcon />&nbsp;Marcas
              </MenuItem>
            </div>
          }
          <MenuItem onClick={() => history.push('/orgaos')}>
            <CorporateFareIcon />&nbsp;Órgãos
          </MenuItem>
          <MenuItem onClick={() => history.push('/parametros')}>
            <SettingsIcon />&nbsp;Parâmetros
          </MenuItem>
          <MenuItem onClick={() => history.push('/usuarios')}>
            <PeopleIcon />&nbsp;Usuários
          </MenuItem>
          <MenuItem onClick={() => history.push('/veiculos')}>
            <DirectionsCarIcon />&nbsp;Veículos
          </MenuItem>
        </Menu>
        <Button
          style={{ color: '#FFF', marginRight: 8 }}
          id="basic-button4"
          onClick={() => history.push('/ordensServico')}
        >
          <ArticleIcon />&nbsp;Ordens de Serviço
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Chip label={
          <Grid container sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
            <Grid item>
              <AccountCircleIcon sx={{ marginRight: 1 }} />
            </Grid>
            <Grid item>
              {user ? user.nome.toUpperCase() : ''}
            </Grid>
          </Grid>
        }
          variant="outlined"
          sx={{ marginLeft: 5, marginRight: 2, color: '#FFF', alignItems: 'center' }} />
        <Hidden lgDown>
          <div className={classes.selectLoja}>
            <SelectOrgao disabled={false} />
          </div>
          <Tooltip title="Sair">
            <IconButton
              style={{ marginLeft: 10 }}
              color="inherit"
              onClick={logout}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            right: 0
          }}>
            <div className={classes.selectLoja}>
              <SelectOrgao disabled={false} />
            </div>
            <Tooltip title="Sair">
              <IconButton
                style={{ marginLeft: 10 }}
                color="inherit"
                onClick={logout}
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Hidden>
      </ Toolbar>
    </AppBar >
  )
}

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  className: PropTypes.string
}

const mapStateToProps = (state) => {
  return state
}
const TopbarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Topbar))
)
export default TopbarPage
