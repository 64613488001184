import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useConfirm } from 'material-ui-confirm'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Autocomplete from '@mui/lab/Autocomplete';
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import GradingIcon from '@mui/icons-material/Grading';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Avatar,
  Backdrop,
  Card,
  Chip,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Skeleton,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TextField,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  authActions,
  ordemServicoAction,
  veiculoAction,
  tipoServicoAction,
  tipoFotoAction,
  usuarioAction,
  orgaoAction
} from '../../_actions'
import { withRouter, Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import { uploadImage } from '_services'
import ImageUploading from 'react-images-uploading'
import { errorsUploadImage, getNomeEvento, getNomeUltimoEvento } from 'utils'
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242, 214, 71) 30%,rgb(0, 0, 0) 70%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242, 214, 71) 30%,rgb(0, 0, 0) 70%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242, 214, 71) 30%, rgb(0, 0, 0) 70%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242, 214, 71) 30%, rgb(0, 0, 0) 70%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PendingActionsRoundedIcon />,
    2: <GradingIcon />,
    3: <EventAvailableIcon />,
    4: <CheckBoxIcon />,
    5: <StarOutlineIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function ColorlibStepIconOsRecusada(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PendingActionsRoundedIcon />,
    2: <HighlightOffIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function isEditable(e) {
  if (e == '2' || e == '3' || e == '4' || e == '5' || e == '6') {
    return false
  }
  else return true
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['PENDENTE',
  'RECEBENDO ORÇAMENTOS',
  'ORÇAMENTO APROVADO',
  'CONCLUÍDA',
  'AVALIADA'];

const OrdemServicoEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()

  const { classes, ordemServico, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false)

  const [id, setId] = useState(0);
  const [idTipoFoto, setIdTipoFoto] = useState(0);

  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [rate, setRate] = useState(0);
  const [hover, setHover] = useState(-1);

  const tiposFoto = useSelector((state) => state.tipoFoto.tipoFoto)
  const tiposServico = useSelector((state) => state.tipoServico.tipoServico)
  const veiculos = useSelector((state) => state.veiculo.veiculo)

  const { usuario } = useSelector(
    (state) => state.usuario)

  const { orgao } = useSelector(
    (state) => state.orgao)

  const options = usuario.map(u => ({ id: u.value, nome: u.text }));

  const [, forceUpdate] = useState(0);
  const [ready, setReady] = useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const labels = {
    1: 'Péssimo',
    2: 'Ruim',
    3: 'Médio',
    4: 'Bom',
    5: 'Excelente',
  };

  useEffect(() => {

    dispatch(tipoFotoAction.getTiposFotoSelect())
    dispatch(tipoServicoAction.getTiposServicoSelect())
    dispatch(veiculoAction.getVeiculoSelect())
    dispatch(orgaoAction.getOrgaoSelect())
    dispatch(usuarioAction.getUsuarioSelect())

    if (params.id) {
      dispatch(ordemServicoAction.getOrdemServicoById(params.id))
    } else {
      dispatch(ordemServicoAction.clear())
    }
  //  console.log("ordemServico", ordemServico)
  //  console.log("idOrgao", idOrgao)

  }, []);

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleAddOnList = e => {
    if (props.ordemServico.ordensServicosFotos.filter(ts => ts.id == parseInt(id)).length > 0) {
      toast.error('Erro ao adicionar! Esta foto já existe!')
    }
    else {
      if (idTipoFoto != 0) {
        props.ordemServico.ordensServicosFotos.push({
          'idTipoFoto': idTipoFoto,
          'caminhoFoto': imagem,
          'idOrdemServico': params.id
        });
      } else {
        toast.error('Selecione um tipo de foto para prosseguir')
      }
      forceUpdate(n => !n);
      setId(0);
      setIdTipoFoto(0);
      setImagem(imagem)
    }
  }

  const onChangeImage = (imageList) => {
   // console.log("imageList", imageList)
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {
        setImagem(response)
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selectedUsuario = usuario.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        usuarioAction.changeDetailsUsuario(
          selectedUsuario[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  const handleRemoveOnList = id => {
    props.ordemServico.ordensServicosFotos = props.ordemServico.ordensServicosFotos.filter(ts => ts.id != parseInt(id));
    forceUpdate(n => !n);
  };

  useEffect(() => {
    // forceUpdate(n => !n);
  }, [open, imagem]);

  useEffect(() => {

  }, [ready, props.ordemServico.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(ordemServicoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      idTipoServico: props.ordemServico.idTipoServico,
      idVeiculo: props.ordemServico.idVeiculo,
      idUsuario: value ? value.id : props.ordemServico.idUsuario,
      data: props.ordemServico.data,
      idOrgao: props.ordemServico.idOrgao,
      descritivo: props.ordemServico.descritivo,
      titulo: props.ordemServico.titulo,
      ultimoEvento: props.ordemServico.ultimoEvento,
      avaliacao: rate,
      descritivoAvaliacao: props.ordemServico.descritivoAvaliacao,
      idUsuarioAvaliacao: props.authentication.user.id,
      ordensServicosFotos: props.ordemServico.ordensServicosFotos
    }


    if (params.id) {
    //  console.log("payload", payload)
      dispatch(ordemServicoAction.editOrdemServicoInfo(params.id, payload, true))
    } else {
     // console.log("payload", payload)
      dispatch(ordemServicoAction.createOrdemServico(payload))
    }

  };

  const handleAprovaOrdemServico = () => {
    confirm({
      title: 'Você deseja aprovar esta Ordem de Serviço?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Aprovando Ordem de Serviço...' : 'Sim, aprovar!',
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoAprova(params.id))
    })
  }

  const handleAprovaOrcamento = (id) => {
    confirm({
      title: 'Você deseja realmente aprovar este Orçamento?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Aprovando Orçamento...' : 'Sim, aprovar!',
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoAprovaOrcamento(id))
    })
  }

  const handleRecusaOrcamento = (id) => {
    confirm({
      title: 'Você deseja realmente recusar este Orçamento?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Recusando Orçamento...' : 'Sim, recusar!',
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoAprovaOrcamento(id))
    })
  }

  const handleRecusaOrdemServico = () => {
    confirm({
      title: 'Você deseja recusar esta Ordem de Serviço?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Recusando Ordem de Serviço...' : 'Sim, recusar!',
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoRecusa(params.id))
    })
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Ordem de Serviço'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Ordem de Serviço'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Ordem de Serviço - Editar Ordem de Serviço'
          : 'Gerência de Ordem de Serviço - Nova Ordem de Serviço'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formOrdemServico"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Ordem de Serviço"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="titulo"
                        label="Título"
                        disabled={isEditable(ordemServico.ultimoEvento) == false}
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('titulo')}
                        value={props.ordemServico.titulo || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12} >
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idTipoServico"
                        disabled={isEditable(ordemServico.ultimoEvento) == false}
                        label="Tipo do Serviço *"
                        value={props.ordemServico.idTipoServico}
                        onChange={handleChange('idTipoServico')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idTipoServico"
                      >
                        <MenuItem disabled value="">
                          <em>Tipo do Serviço *</em>
                        </MenuItem>
                        {!isEmpty(tiposServico) &&
                          undefined !== tiposServico &&
                          tiposServico.length &&
                          tiposServico.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        multiline
                        disabled={isEditable(ordemServico.ultimoEvento) == false}
                        minRows={2}
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="descritivo"
                        label="Descritivo"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('descritivo')}
                        value={props.ordemServico.descritivo || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12} >
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idVeiculo"
                        disabled={isEditable(ordemServico.ultimoEvento) == false}
                        label="Veículo *"
                        value={props.ordemServico.idVeiculo}
                        onChange={handleChange('idVeiculo')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idVeiculo"
                      >
                        <MenuItem disabled value="">
                          <em>Veículo *</em>
                        </MenuItem>
                        {!isEmpty(veiculos) &&
                          undefined !== veiculos &&
                          veiculos.length &&
                          veiculos.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12} style={{ paddingTop: 31 }}>
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        disabled={isEditable(ordemServico.ultimoEvento) == false}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        margin="normal"
                        variant="outlined"
                        defaultValue={null}
                        getOptionLabel={(option) => (option ? option.nome : "")}
                        renderInput={(params) => <TextField {...params} label="Usuário *... " fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        type="date"
                        id="data"
                        disabled={isEditable(ordemServico.ultimoEvento) == false}
                        label="Data *"
                        margin="normal"
                        onChange={handleChange('data')}
                        value={props.ordemServico.data ? moment(props.ordemServico.data).format("yyyy-MM-DD") : ''}
                        variant="outlined"
                        validators={['required']}
                        InputLabelProps={{
                          shrink: true
                        }}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idOrgao"
                        label="Órgão *"
                        margin="normal"
                        value={idOrgao != 0 ? idOrgao : props.ordemServico.idOrgao}
                        onChange={handleChange('idOrgao')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        disabled={(idOrgao != null || idOrgao != 0) && (isEditable(ordemServico.ultimoEvento) == false)}
                        labelId="idOrgao"
                      >
                        <MenuItem disabled value="">
                          <em>Órgão</em>
                        </MenuItem>
                        {!isEmpty(orgao) &&
                          undefined !== orgao &&
                          orgao.length &&
                          orgao.map((row) => (
                            <MenuItem key={row.value} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    {params.id &&
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextField
                          className={classes.textField}
                          fullWidth
                          label="Status"
                          margin="normal"
                          variant="outlined"
                          value={ordemServico && ordemServico.ultimoEvento ? getNomeUltimoEvento(ordemServico.ultimoEvento) : ''}
                        />
                      </Grid>
                    }
                  </Grid>
                </CardContent>
                <Divider />
                <Card>
                  <CardHeader title={<Typography variant="h6" align="center">
                    <b>{'Etapas da Ordem de Serviço'}</b>
                  </Typography>}>
                  </CardHeader>
                  <CardContent>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      {params.id && ordemServico.eventos && ordemServico.eventos.length > 0 ?
                        //se a O.S foi recusada, vai exibir somente os dois eventos
                        (ordemServico.ultimoEvento == '4' ?
                          <Stepper alternativeLabel activeStep={parseInt(ordemServico.ultimoEvento) - 1} connector={<ColorlibConnector />}>
                            {ordemServico.eventos.map((e) => (
                              <Step key={e.id}>
                                <StepLabel StepIconComponent={ColorlibStepIconOsRecusada}><b>{getNomeEvento(e)}</b><br></br>{moment(e.data).format("DD/MM/YYYY HH:mm")}</StepLabel>
                              </Step>
                            ))
                            }
                          </Stepper>
                          : (ordemServico.ultimoEvento != null && ordemServico.ultimoEvento != '4') ?
                            <Stepper alternativeLabel activeStep={parseInt(ordemServico.ultimoEvento) - 1} connector={<ColorlibConnector />}>
                              {ordemServico.eventos.map((e) => (
                                <Step key={e.id}>
                                  <StepLabel StepIconComponent={ColorlibStepIcon}><b>{getNomeEvento(e)}</b><br></br>{moment(e.data).format("DD/MM/YYYY HH:mm")}</StepLabel>
                                </Step>
                              ))
                              }
                            </Stepper>
                            : (ordemServico.ultimoEvento > '5') &&
                            <Stepper alternativeLabel activeStep={parseInt(ordemServico.ultimoEvento) - 2} connector={<ColorlibConnector />}>
                              {ordemServico.eventos.map((e) => (
                                <Step key={e.id}>
                                  <StepLabel StepIconComponent={ColorlibStepIcon}><b>{getNomeEvento(e)}</b><br></br>{moment(e.data).format("DD/MM/YYYY HH:mm")}</StepLabel>
                                </Step>
                              ))
                              }
                            </Stepper>
                        )
                        :
                        <Stepper alternativeLabel activeStep={-1} connector={<ColorlibConnector />}>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel StepIconComponent={ColorlibStepIcon}><b>{label}</b></StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      }
                    </Grid>
                  </CardContent>
                </Card>
                {ordemServico.id && ordemServico.ultimoEvento == '2' && (authActions.isPrefeitura() || authActions.isGestor()) ?
                  <Card>
                    <Divider />
                    <CardContent>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Typography variant="h6" align="center">
                                <b>{'Orçamentos para aprovação'}</b>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Prazo</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Empresa</TableCell>
                            <TableCell align="right">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {undefined !== ordemServico.ordensServicosOrcamentos && ordemServico.ordensServicosOrcamentos.length
                            ? ordemServico.ordensServicosOrcamentos.map((n) => {
                              return (
                                <TableRow hover key={n.id}>
                                  <TableCell component="th" scope="row">
                                    {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      aria-label="Aprovar"
                                      component="a"
                                      onClick={() => handleAprovaOrcamento(n.id)}
                                      size="small"
                                    >
                                      <Tooltip title={'Aprovar orçamento'}>
                                        <Tooltip >
                                          <DoneIcon />
                                        </Tooltip>
                                      </Tooltip>
                                    </IconButton>
                                    <IconButton
                                      aria-label="Recusar"
                                      onClick={() => handleRecusaOrcamento(n.id)}
                                      size="small"
                                    >
                                      <Tooltip title="Recusar orçamento">
                                        <CloseIcon />
                                      </Tooltip>
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              )
                            })
                            :
                            <TableRow hover>
                              <TableCell rowSpan={3} colSpan={5} align="center">Nenhum orçamento recebido até o momento</TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                  : ordemServico.id && ordemServico.ultimoEvento == '3' && (authActions.isPrefeitura() || authActions.isGestor()) ?
                    <Card>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Typography variant="h6" align="center">
                                <b>{'Orçamento aprovado para a Ordem de Serviço'}</b>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Prazo</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Empresa</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {undefined !== ordemServico.ordensServicosOrcamentos && ordemServico.ordensServicosOrcamentos.length
                            ? ordemServico.ordensServicosOrcamentos.map((n) => {
                              return (
                                (n.statusOrcamento == '1' ?
                                  <TableRow hover key={n.id}>
                                    <TableCell component="th" scope="row">
                                      {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                                    </TableCell>
                                  </TableRow>
                                  : null
                                )
                              )
                            })
                            :
                            null
                          }
                        </TableBody>
                      </Table>
                    </Card>
                    : null
                }
                <Card>
                  <Divider />
                  <CardHeader title={<Typography variant="h6" align="center">
                    <b>{'Fotos'}</b>
                  </Typography>}>
                  </CardHeader>
                  <Divider />
                  <CardContent>
                    {isEditable(ordemServico.ultimoEvento) == true ?
                      <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <Backdrop className={classes.backdrop} open={open}>
                            <Grid
                              container
                              spacing={3}
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              alignContent="center"
                              wrap="nowrap"
                            >
                              <Grid item>
                                <CircularProgress color="primary" />
                              </Grid>
                              <Grid item>
                                <Typography variant="h5" color="textPrimary">
                                  Enviando foto...
                                </Typography>
                              </Grid>
                            </Grid>
                          </Backdrop>
                          <ImageUploading
                            maxFileSize={500000}
                            onError={(errors) => errorsUploadImage(errors)}
                            value={images}
                            acceptType={['jpeg', 'jpg']}
                            onChange={onChangeImage}
                            resolutionWidth={1100}
                            resolutionHeight={1100}
                            resolutionType={"less"}
                            dataURLKey="data_url"
                          >
                            {({ onImageUpload }) => (
                              <CardActions className={classes.uploadButton} align="center">
                                <Button variant="outlined" style={{ color: '#000', borderColor: '#000', justifyContent: 'center' }} onClick={onImageUpload}>
                                  Fazer upload de foto
                                </Button>
                              </CardActions>
                            )}
                          </ImageUploading>
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <SelectValidator
                            variant="outlined"
                            id="idTipoFoto"
                            label="Tipo Foto"
                            margin="normal"
                            value={idTipoFoto}
                            onChange={(e) => setIdTipoFoto(e.target.value)}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            labelId="idTipoFoto"
                          >
                            <MenuItem disabled value="">
                              <em>Tipo Foto</em>
                            </MenuItem>
                            {!isEmpty(tiposFoto) &&
                              undefined !== tiposFoto &&
                              tiposFoto.length &&
                              tiposFoto.map((row) => (
                                <MenuItem key={row.value} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                          <Button style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }} name="btnUsuarios" id="btnUsuarios" variant="contained" className="btn" component='a' onClick={handleAddOnList}>Adicionar Foto&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                        </Grid>
                      </Grid>
                      : null
                    }
                    <Grid container lg={12} md={12} xl={12} xs={12} spacing={2}>
                      {props.ordemServico.ordensServicosFotos && props.ordemServico.ordensServicosFotos.length > 0 ?
                        props.ordemServico.ordensServicosFotos.map(o => {
                          return (
                            <Grid item lg={3} md={3} xl={3} xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Grid container sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Avatar
                                  sx={{ width: '100%', height: 180, objectFit: 'cover' }}
                                  key={(imagem != '' && imagem != null ? imagem.substr(imagem.lastIndexOf('/'), imagem.length - imagem.lastIndexOf('/')) : '')}
                                  variant="rounded"
                                  src={o.caminhoFoto}
                                />
                                {isEditable(ordemServico.ultimoEvento) == true ?
                                  <Button variant="outlined" onClick={() => handleRemoveOnList(o.id)} size="small" sx={{ color: '#000', marginTop: 1, borderColor: '#000' }}>
                                    Remover foto&nbsp;<DeleteIcon />
                                  </Button>
                                  : null}
                              </Grid>
                            </Grid>
                          )
                        }) :
                        isEditable(ordemServico.ultimoEvento) == false ?
                          <Table>
                            <TableRow hover>
                              <TableCell rowSpan={3} colSpan={5} align="center">Nenhuma foto carregada</TableCell>
                            </TableRow>
                          </Table>
                          : null
                      }
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                        * Campos obrigatórios
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} style={{
                      display: 'flex',
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlgin: 'right',
                      justifyContent: 'right'
                    }}>
                      <Button
                        variant="outlined"
                        style={{ color: '#000', borderColor: '#000' }}
                        onClick={() => history.push('/ordensServico')}
                      >
                        Cancelar
                      </Button>
                      {ordemServico.id && ordemServico.ultimoEvento == '1' && (authActions.isPrefeitura() || authActions.isGestor()) ?
                        <>
                          <Button
                            variant="outlined"
                            style={{ color: '#000', borderColor: '#000' }}
                            onClick={handleRecusaOrdemServico}
                            disabled={props.ordemServico.isLoading}
                            startIcon={<CloseIcon />}
                            endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                          >
                            {props.ordemServico.isLoading ? 'Recusando ordem de serviço...' : 'Recusar'}
                          </Button>
                          <Button
                            variant="outlined"
                            style={{ color: '#000', borderColor: '#000' }}
                            onClick={handleAprovaOrdemServico}
                            disabled={props.ordemServico.isLoading}
                            startIcon={<DoneIcon />}
                            endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                          >
                            {props.ordemServico.isLoading ? 'Aprovando ordem de serviço...' : 'Aprovar'}
                          </Button>
                        </>
                        : null}
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: '#000', color: '#FFF' }}
                        disabled={props.ordemServico.isLoading || ordemServico.ultimoEvento == '2'}
                        startIcon={<SaveIcon />}
                        endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                      >
                        {params.id ? (props.ordemServico.isLoading ? 'Atualizando ordem de serviço...' : 'Atualizar') : (props.ordemServico.isLoading ? 'Salvando ordem de serviço...' : 'Salvar')}
                      </Button>
                    </Grid>
                  </CardActions>
                </Card>
              </ValidatorForm>
            </Card>
            {ordemServico &&
              ordemServico.eventos &&
              ordemServico.eventos.length > 0 &&
              ordemServico.eventos[0].tipo == '5' &&
              <>
                <Divider />
                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h6" align="center">
                        <b>{'Avaliação'}</b>
                      </Typography>}>
                  </CardHeader>
                  <CardContent sx={{ marginTop: 0, paddingTop: 0 }}>
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <Grid item lg={7} md={7} xl={7} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          multiline
                          minRows={2}
                          id="descritivoAvaliacao"
                          label="Descreva como foi o atendimento para o serviço prestado"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={handleChange('descritivoAvaliacao')}
                          value={props.ordemServico.descritivoAvaliacao || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <Typography component="legend">Avalie, com uma nota</Typography>
                        <Rating
                          name="simple-controlled"
                          value={rate ? rate : props.ordemServico.avaliacao}
                          onChange={(event, newRate) => {
                            setRate(newRate);
                          }}
                          onChangeActive={(event, newHover) => {
                            setHover(newHover);
                          }}
                        />
                        {rate !== null && (
                          <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : rate]}</Box>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            }
          </Grid>
        </Grid>
      </Grid >
    </Page >
  )
}

OrdemServicoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedOrdemServicoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(OrdemServicoEdit))
)
export { connectedOrdemServicoEditPage as OrdemServicoEdit }
