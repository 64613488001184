import { crudService } from '../_services/'

export const tipoServicoAction = {
  getTiposServicoSelect
}

function getTiposServicoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposServico/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposServicosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTiposServicosList(tipoServico) {
  return {
    type: 'FETCHED_ALL_TIPO_SERVICO',
    tipoServico: tipoServico
  }
}

