import React, { useLayoutEffect, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Typography,
  Grid
} from '@mui/material'
import { withStyles } from '@mui/styles'
import messageTime from 'utils/stringTimeHello'
import { Page } from 'components'
import { withRouter } from 'react-router-dom'
import { authActions, dashboardAction } from '../../_actions'
import CardHorasRecebimentoOrcamentos from '../Dashboard/components/CardHorasRecebimentoOrcamentos'
import CardOrdemServico from '../Dashboard/components/CardOrdemServico'
import CardUsuarios from '../Dashboard/components/CardUsuarios'
import CardEmpresas from '../Dashboard/components/CardEmpresas'
import GraficoOrdensServicoStatusChartCard from '../Dashboard/components/GraficoOrdensServicoStatusChartCard'
import GraficoOrdensServicoPorMesChartCard from '../Dashboard/components/GraficoOrdensServicoPorMesChartCard'
import background from "../../img/background.png";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textAlign: 'center'
  },
  gridCards: {
    //alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  }
});

const Dashboard = (props) => {

  const { className, classes, ...rest } = props
  const { match: { params } } = props;
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authentication.user)

  useLayoutEffect(() => {
    dispatch(authActions.refresh())

  }, [])

  useEffect(() => {
    dispatch(dashboardAction.getDashboard())
  }, [])

  return (
    <Page
      className={classes.root}
      title='API SINAPI - Dashboard'
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Grid alignItems="center" container justifyContent="center" spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography component="h1" gutterBottom variant="h5">
              {messageTime()}, {user ? user.nome : ''}
            </Typography>
            <br></br>
            <Typography component="h2" gutterBottom variant="h6">
              {'Seja bem vindo'}
            </Typography>
          </Grid>
          <Grid container className={classes.gridCards} spacing={3}>
            <Grid item lg={3} sm={3} xl={3} xs={12}>
              <CardOrdemServico />
            </Grid>
            <Grid item lg={3} sm={3} xl={3} xs={12}>
              <CardUsuarios />
            </Grid>
            <Grid item lg={3} sm={3} xl={3} xs={12}>
              <CardEmpresas />
            </Grid>
            <Grid item lg={3} sm={3} xl={3} xs={12}>
              <CardHorasRecebimentoOrcamentos />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <GraficoOrdensServicoStatusChartCard />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <GraficoOrdensServicoPorMesChartCard />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Page >
  )
}
Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedDashboardPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Dashboard))
)
export { connectedDashboardPage as Dashboard }
