import React, { useEffect, useState } from 'react';
import clsx from 'clsx'
import Card from '@mui/material/Card';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { Avatar, Button, Typography, CardContent, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { history } from '../../../../utils'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardAction, ordemServicoAction } from '_actions'
import { makeStyles } from '@mui/styles'
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    marginRight: 10,
    minHeight: 120,
    minWidth: 300
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main
  }
}));

const CardOrdemServico = (props) => {
  const { className, ...rest } = props
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');

  const ordensServicoPendentes = useSelector((state) => (state.dashboard ? state.dashboard.dashboard.ordensServicoPendentes : undefined))

  useEffect(() => {
    dispatch(dashboardAction.getDashboard());
  }, [])

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const handleVisualizarOrdensServicoPendentes = () => {

    history.push('/ordensServico');

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: "1"
      };

      dispatch(ordemServicoAction.getOrdemServico(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item styles={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item>
              <Typography variant="h2" className={classes.title}>
                {ordensServicoPendentes ? ordensServicoPendentes : 0}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" gutterBottom>
                Ordens de serviço pendentes
              </Typography>
              {/*<Grid item sx={{ justifyContent: 'center' }}>*/}
              {/*  <Button*/}
              {/*    onClick={(e) => handleVisualizarOrdensServicoPendentes()}*/}
              {/*    variant="contained"*/}
              {/*    sx={{ backgroundColor: '#000' }}*/}
              {/*  >*/}
              {/*    Visualizar*/}
              {/*  </Button>*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
          <Grid item>
            <Avatar sx={{ width: 65, height: 65, bgcolor: '#F2D647' }}>
              <MiscellaneousServicesIcon sx={{ fontSize: 35, color: '#000' }} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
}

CardOrdemServico.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string
}

export default CardOrdemServico
