const initialState = {
  usuarioEmpresa: [],
  id: '',
  isLoading: false,
  idUsuario: '',
  idEmpresa: '',
  excluido: null,
  empresa: {}
}

export function usuarioEmpresa(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USUARIO_EMPRESA_SUCCESS':
      return {
        ...state,
        idEmpresa: '',
        usuarioEmpresa: [
          ...state.usuarioEmpresa,
          action.usuarioEmpresa
        ]
      }
    case 'FETCHED_ALL_USUARIO_EMPRESA':
      return {
        ...state,
        usuarioEmpresa: action.usuarioEmpresa
      }
    case 'USUARIO_EMPRESA_DETAIL':
      return {
        ...state,
        id: action.id,
        idUsuario: action.idUsuario,
        idEmpresa: action.idEmpresa,
        excluido: action.excluido,
        empresa: action.empresa
      }
    case 'USUARIO_EMPRESA_UPDATED':
      return state
    case 'USUARIO_EMPRESA_ADD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_EMPRESA_ADD_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_EMPRESA_ADD_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_EMPRESA_CLEAR':
      return initialState
    case 'USUARIO_EMPRESA_CLEAR_ALL':
      return {
        ...state,
        usuarioEmpresa: []
      }
    case 'USUARIO_EMPRESA_CLEAR_SELECTED':
      return {
        ...state,
        id: '',
        isLoading: false,
        idUsuario: '',
        idEmpresa: '',
        excluido: null,
        empresa: {},
        categoriaId: 0
      }
    case 'CHANGE_DETAILS_USUARIO_EMPRESA':
      return {
        ...state,
        id: action.id,
        idUsuario: action.idUsuario,
        idEmpresa: action.idEmpresa,
        excluido: action.excluido,
        empresa: action.empresa
      }
    case 'AUTHENTICATION_REFRESH_SUCCESS':
      return {
        ...state,
        usuarioEmpresa: action.user.usuariosEmpresas
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        usuarioEmpresa: action.user.usuariosEmpresas
      }
    default:
      return state
  }
}
