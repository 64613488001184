import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Badge,
  Grid,
  TextField,
  MenuItem
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  authActions,
  orgaoAction,
  cidadeAction,
  estadoAction,
  usuarioAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { toast } from 'react-toastify'
import { history, cnpjMask, stringUtils, phoneMask } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';
import { UsuarioEditModal } from './usuarioEditModal.component';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const OrgaoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, orgao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const confirm = useConfirm()

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const [idUsuario, setIdUsuario] = useState(0);
  const [textUsuario, setTextUsuario] = useState('');

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false)

  const [valueUsuario, setValueUsuario] = React.useState(null);
  const [inputValueUsuario, setInputValueUsuario] = React.useState('');
  const [openUsuario, setOpenUsuario] = React.useState(false)

  const [usuarioByOrgao, setUsuarioByOrgao] = React.useState(false);

  const [modalUsuario, setModalUsuario] = useState(false);
  const toggleUsuario = () => setModalUsuario(!modalUsuario);

  const { cidade } = useSelector(
    (state) => state.cidade
  )

  const { usuario } = useSelector(
    (state) => state.usuario
  )

  const estados = useSelector((state) => state.estado.estado)

  const options = cidade.map(u => ({ id: u.value, nome: u.text }));

  const optionsUsuarios = usuario.map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {

    dispatch(estadoAction.getEstadoSelect())
    dispatch(usuarioAction.getUsuarioSemOrgaoAindaSelect())

    if (params.id) {
      dispatch(orgaoAction.getOrgaoById(params.id))
    } else {
      dispatch(orgaoAction.clear())
    }
   
  }, []);

  const handleAprovaCadastroOrgao = () => {
    confirm({
      title: 'Você deseja liberar o acesso ao sistema para este Órgão?',
      description: 'Essa operação é irreversível',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Aprovando...' : 'Sim, aprovar!',
    }).then(() => {
      dispatch(orgaoAction.editAprovaCadastroOrgao(params.id))
    })
  }

  const handleRecusaCadastroOrgao = () => {
    confirm({
      title: 'Você deseja recusar o acesso ao sistema para este óÓrgão?',
      description: 'Essa operação é irreversível',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Recusando...' : 'Sim, recusar!',
    }).then(() => {
      dispatch(orgaoAction.editRecusaCadastroOrgao(params.id))
    })
  }

  const handleRemoveOnList = id => {
    props.orgao.usuariosOrgaos = props.orgao.usuariosOrgaos.filter(ts => ts.idUsuario != parseInt(id));
    forceUpdate(n => !n);
  };

  const handleOpenModal = () => {
    toggleUsuario();
    setUsuarioByOrgao(true);
  }

  useEffect(() => {
  }, [idUsuario])

  useEffect(() => {
    if (props.orgao.idEstado) {
      dispatch(cidadeAction.getCidadeByIdEstado(props.orgao.idEstado))
    }
  }, [props.orgao.idEstado])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  useEffect(() => {
    if (valueUsuario) {
      handleChangeAutoCompleteUsuario(valueUsuario)
    }
  }, [valueUsuario])

  const handleChangeAutoComplete = (obj) => {

    if (obj != null) {
      let selectedCidade = cidade.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        cidadeAction.changeDetailsCidade(
          selectedCidade[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  const handleChangeAutoCompleteUsuario = (obj) => {
    if (obj != null) {
      setIdUsuario(obj.id);
      setTextUsuario(obj.nome);
    }
    setOpenUsuario(false)
  }

  useEffect(() => {

  }, [ready, props.orgao.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(orgaoAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.orgao.usuariosOrgaos])

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.orgao.nome,
      idCidade: value ? value.id : 0,
      idEstado: props.orgao.idEstado,
      celular: props.orgao.celular && props.orgao.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      cnpj: props.orgao.cnpj && props.orgao.cnpj.split('.').join('').replace(/[-/.]/g, ''),
      email: props.orgao.email,
      endereco: props.orgao.endereco,
      telefone: props.orgao.telefone && props.orgao.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      usuariosOrgaos: props.orgao.usuariosOrgaos
    }

    if (params.id) {
      dispatch(orgaoAction.editOrgaoInfo(params.id, payload, true))
      dispatch(usuarioAction.getUsuarioSelect())
    } else {
      dispatch(orgaoAction.createOrgao(payload))
      dispatch(usuarioAction.createUsuario(payload.usuariosOrgaos, true))
      dispatch(usuarioAction.getUsuarioSelect())
    }
  };

  const handleAddOnList = () => {
    if (props.orgao.usuariosOrgaos.filter(ts => ts.idUsuario == parseInt(idUsuario)).length > 0) {
      toast.error('Erro ao adicionar! Este usuário já existe!')
    }
    else {
      props.orgao.usuariosOrgaos.push({
        'idUsuario': idUsuario,
        'idOrgao': params.id ? params.id : 0,
        'usuario': {
          'nome': textUsuario ? textUsuario : ''
        }
      });

      forceUpdate(n => !n);
      setIdUsuario(0);
      setValueUsuario(null);
    }
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Órgão'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Órgão'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Órgãos - Editar Órgão'
          : 'Gerência de Órgãos - Novo Órgão'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formOrgao"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Órgãos"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.orgao.nome || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idEstado"
                        label="Estado *"
                        value={props.orgao.idEstado}
                        onChange={handleChange('idEstado')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idEstado"
                      >
                        <MenuItem disabled value="">
                          <em>Estado *</em>
                        </MenuItem>
                        {!isEmpty(estados) &&
                          undefined !== estados &&
                          estados.length &&
                          estados.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingTop: 31 }}>
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        margin="normal"
                        variant="outlined"
                        defaultValue={null}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione uma cidade..." fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cnpj"
                        label="CNPJ"
                        margin="normal"
                        onChange={handleChange('cnpj')}
                        value={cnpjMask(props.orgao.cnpj) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="endereco"
                        label="Endereço"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('endereco')}
                        value={props.orgao.endereco || ''}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid container lg={12} md={12} xl={12} xs={12}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Dados de contato
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          validators={['isEmail']}
                          fullWidth
                          label="E-mail"
                          errorMessages={[
                            'E-mail inválido'
                          ]}
                          value={props.orgao.email || ''}
                          className={classes.textField}
                          onChange={handleChange('email')}
                          id="email"
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="telefone"
                          label="Telefone"
                          margin="normal"
                          onChange={handleChange('telefone')}
                          value={phoneMask(props.orgao.telefone) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="celular"
                          label="Celular"
                          margin="normal"
                          onChange={handleChange('celular')}
                          value={phoneMask(props.orgao.celular) || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid container lg={12} md={12} xl={12} xs={12} spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Usuários vinculados ao órgão
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <div className="selectgroup selectgroup-pills" style={{ marginLeft: 5, display: 'flex', flexDirection: 'row', alignItems: 'top' }}>
                        {
                          (props.orgao.usuariosOrgaos != null && props.orgao.usuariosOrgaos.length > 0 ?
                            props.orgao.usuariosOrgaos.map(n => {
                              return (
                                (n.id !== '' ?
                                  <Button onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id} style={{ backgroundColor: '#F2D647', marginLeft: 7, marginRight: 7 }}>
                                    <Badge style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7, color: '#000' }}>
                                      {(n.usuario ? n.usuario.nome : n.idUsuario)}
                                    </Badge>
                                    &nbsp;<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000' }} />
                                  </Button>
                                  : null)
                              );
                            })
                            : null)
                        }
                      </div>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} xs={12} align="right">
                      <Autocomplete
                        value={valueUsuario}
                        onChange={(event, newValueUsuario) => {
                          setValueUsuario(newValueUsuario);
                        }}
                        inputValue={inputValueUsuario}
                        onInputChange={(event, newInputValueUsuario) => {
                          setInputValueUsuario(newInputValueUsuario);
                        }}
                        id="controllable-states-demo"
                        options={optionsUsuarios}
                        defaultValue={null}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextValidator {...params} label="Selecione um usuário ..."
                          variant="outlined" fullWidth />}
                        style={{ width: "312px" }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                      <Button
                        style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }}
                        name="btnUsuarios"
                        id="btnUsuarios"
                        className="btn"
                        variant="contained"
                        component='a'
                        onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={12}>
                      <Button style={{ marginLeft: '15px', backgroundColor: '#000' }}
                        name="btnUsuarios"
                        id="btnUsuarios"
                        className="btn"
                        variant="contained"
                        component='a'
                        onClick={() => handleOpenModal()}>Cadastrar usuário&nbsp;<FontAwesomeIcon icon={faUserPlus} />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlign: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      variant="outlined"
                      style={{ color: '#000', borderColor: '#000' }}
                      onClick={() => history.push('/Orgaos')}
                    >
                      Cancelar
                    </Button>
                    {orgao.id && (authActions.isPrefeitura() || authActions.isGestor()) ?
                      <>
                        <Button
                          variant="outlined"
                          style={{ color: '#000', borderColor: '#000' }}
                          onClick={handleRecusaCadastroOrgao}
                          disabled={props.orgao.cadastroLiberado == false || props.orgao.isLoading}
                          startIcon={<CloseIcon />}
                          endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                        >
                          {props.orgao.cadastroLiberado == false ? 'Acesso recusado' : props.orgao.isLoading ? 'Recusando acesso...' : 'Recusar acesso'}
                        </Button>
                        <Button
                          variant="outlined"
                          style={{ color: '#000', borderColor: '#000' }}
                          onClick={handleAprovaCadastroOrgao}
                          disabled={props.orgao.cadastroLiberado == true || props.orgao.isLoading}
                          startIcon={<DoneIcon />}
                          endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                        >
                          {props.orgao.cadastroLiberado == true ? 'Acesso liberado' : props.orgao.isLoading ? 'Liberando acesso...' : 'Liberar acesso'}
                        </Button>
                      </>
                      : null}
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#000' }}
                      type="submit"
                      disabled={props.orgao.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.orgao.isLoading ? 'Atualizando órgão...' : 'Atualizar') : (props.orgao.isLoading ? 'Salvando órgão...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {
        (modalUsuario ?
          <UsuarioEditModal toggle={toggleUsuario} modal={modalUsuario} props={props} idorgao={params.id} idUsuario={idUsuario}
            isorgao={usuarioByOrgao} />
          :
          null
        )
      }
    </Page >
  )
}

OrgaoEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  idUsuario: PropTypes.object
}
const mapStateToProps = (state) => {
  return state
}

const connectedOrgaoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(OrgaoEdit))
)
export { connectedOrgaoEditPage as OrgaoEdit }
