const initialState = {
  loading: false,
  usuario: [],
  open: false,
  id: '',
  nome: ''
}

export function tipoServico(state = initialState, action) {
  switch (action.type) {    
    case 'FETCHED_ALL_TIPO_SERVICO':
      return {
        ...state,
        tipoServico: action.tipoServico
      }    
    default:
      return state
  }
}
