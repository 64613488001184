export function getNomeEvento(evento) {
  try {
    if (evento.tipo == "1") {
      return "PENDENTE";
    } else if (evento.tipo == "2") {
      return "RECEBENDO ORÇAMENTOS";
    } else if (evento.tipo == "3") {
      return "ORÇAMENTO APROVADO";
    } else if (evento.tipo == "4") {
      return "RECUSADA";
    } else if (evento.tipo == "5") {
      return "CONCLUÍDA";
    } else if (evento.tipo == "6") {
      return "AVALIADO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getNomeUltimoEvento(ultimoEvento) {
  try {
    if (ultimoEvento == "1") {
      return "PENDENTE";
    } else if (ultimoEvento == "2") {
      return "RECEBENDO ORÇAMENTOS";
    } else if (ultimoEvento == "3") {
      return "ORÇAMENTO APROVADO";
    } else if (ultimoEvento == "4") {
      return "RECUSADA";
    } else if (ultimoEvento == "5") {
      return "CONCLUÍDA";
    } else if (ultimoEvento == "6") {
      return "AVALIADO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getCor(ultimoEvento) {
  try {
    if (ultimoEvento == "1") {
      return "#9E9E9E";
    } else if (ultimoEvento == "2") {
      return "#673AB7";
    } else if (ultimoEvento == "3") {
      return "#2196F3";
    } else if (ultimoEvento == "4") {
      return "#FF5722";
    } else if (ultimoEvento == "5") {
      return "#4CAF50";
    } else if (ultimoEvento == "6") {
      return "#FF9800";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}
