const initialState = {
  usuarioOrgao: [],
  id: '',
  isLoading: false,
  idUsuario: '',
  idOrgao: '',
  excluido: null,
  orgao: {}
}

export function usuarioOrgao(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USUARIO_ORGAO_SUCCESS':
      return {
        ...state,
        idOrgao: '',
        usuarioOrgao: [
          ...state.usuarioOrgao,
          action.usuarioOrgao
        ]
      }
    case 'FETCHED_ALL_USUARIO_ORGAO':
      return {
        ...state,
        usuarioOrgao: action.usuarioOrgao
      }
    case 'USUARIO_ORGAO_DETAIL':
      return {
        ...state,
        id: action.id,
        idUsuario: action.idUsuario,
        idOrgao: action.idOrgao,
        excluido: action.excluido,
        orgao: action.orgao
      }
    case 'USUARIO_ORGAO_UPDATED':
      return state
    case 'USUARIO_ORGAO_ADD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_ORGAO_ADD_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ORGAO_ADD_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ORGAO_CLEAR':
      return initialState
    case 'USUARIO_ORGAO_CLEAR_ALL':
      return {
        ...state,
        usuarioOrgao: []
      }
    case 'USUARIO_ORGAO_CLEAR_SELECTED':
      return {
        ...state,
        id: '',
        isLoading: false,
        idUsuario: '',
        idOrgao: '',
        excluido: null,
        orgao: {}
      }
    case 'CHANGE_DETAILS_USUARIO_ORGAO':
      return {
        ...state,
        id: action.id,
        idUsuario: action.idUsuario,
        idOrgao: action.idOrgao,
        excluido: action.excluido,
        orgao: action.orgao
      }
    case 'AUTHENTICATION_REFRESH_SUCCESS':
      return {
        ...state,
        usuarioOrgao: action.user.usuariosOrgaos
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        usuarioOrgao: action.user.usuariosOrgaos
      }
    default:
      return state
  }
}
