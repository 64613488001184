import { crudService } from '../_services/'

export const tipoFotoAction = {
  getTiposFotoSelect
}

function getTiposFotoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposFotos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposFotosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTiposFotosList(tipoFoto) {
  return {
    type: 'FETCHED_ALL_TIPO_FOTO',
    tipoFoto: tipoFoto
  }
}

