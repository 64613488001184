import { toast } from 'react-toastify'
import { history } from '../utils'

export function errorHandler(error) {
  if (error.response.status === 400) {
    if (error.response.data != null) {
      toast.error(error.response.data, {
        autoClose: 6600
      })
    }
    else
    {
      toast.error(`Opss! Algo deu errado 😥`, {
        autoClose: 6600
      })
    }

    return Promise.reject(error.response || error.message)
  }
  if (error.response.status === 403) {
    toast.error(`Sem permissão para realizar essa operação!  🔐`)
    return Promise.reject(error.response || error.message)
  }
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('expires')
    history.push('/login')
    return Promise.reject(error)
  }
  if (error.response.status === 404) {
    toast.error(`Opss! ${error.response.data} 😥`)
    return Promise.reject(error.response || error.message)
  }
  return Promise.reject(error.response || error.message)
}
