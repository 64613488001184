import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ordemServicoAction } from '../../_actions'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Chip,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history, getNomeUltimoEvento, getCor } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';


const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const OrdemServico = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, className } = props
  const { ordemServico } = props.ordemServico
  const { totalRegistros } = props.ordemServico
  const { isLoading } = props.ordemServico

  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');
  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: status
      };

      dispatch(ordemServicoAction.getOrdemServico(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idOrgao]);

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: status
      };
      dispatch(ordemServicoAction.getOrdemServico(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [status])

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.ordemServico.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Ordem de Serviço?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Excluindo Ordem de Serviço...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(ordemServicoAction.deleteOrdemServicoById(id))
    })
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  const handleChangeRadio = prop => event => {
    setStatus(event.target.value);
  };

  return (
    <Page className={classes.root} title="OrdemServicos">
      <AccessControl
        rule={'ordensServico.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== ordemServico && ordemServico.length > 0 ? (
              <TitleContent
                rule={'ordensServico.create'}
                length={ordemServico.length}
                subTitle={'GERÊNCIA DE ORDEM DE SERVIÇO'}
                title={'Lista de Ordem de Serviço'}
                href={'/ordemServico'}
              />
            ) : (
              <TitleContent
                rule={'ordensServico.create'}
                length={0}
                subTitle={'GERÊNCIA DE ORDEM DE SERVIÇO'}
                title={'Lista de Ordem de Serviço'}
                href={'/ordemServico'}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              <Grid item xl={9} lg={9} md={9} xs={12} align="right">
                <FormControl>
                  <FormLabel>
                    <Typography variant="subtitle1">Filtrar por Status </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={handleChangeRadio('status')}
                    value={status}
                  >
                    <FormControlLabel value="1" control={<Radio size="sm" sx={{
                      '&.Mui-checked': {
                        color: getCor(1)
                      }
                    }} />} label={<Typography variant="subtitle2">Pendente</Typography>} />

                    <FormControlLabel value="2" control={<Radio size="sm" sx={{
                      '&.Mui-checked': {
                        color: getCor(2)
                      }
                    }} />} label={<Typography variant="subtitle2">Recebendo orçamentos</Typography>} />

                    <FormControlLabel value="3" control={<Radio size="sm" sx={{
                      '&.Mui-checked': {
                        color: getCor(3)
                      }
                    }} />} label={<Typography variant="subtitle2">Orçamento aprovado</Typography>} />

                    <FormControlLabel value="4" control={<Radio size="sm" sx={{
                      '&.Mui-checked': {
                        color: getCor(4)
                      }
                    }} />} label={<Typography variant="subtitle2">Recusada</Typography>} />

                    <FormControlLabel value="5" control={<Radio size="sm" sx={{
                      '&.Mui-checked': {
                        color: getCor(5)
                      }
                    }} />} label={<Typography variant="subtitle2">Concluída</Typography>} />

                    <FormControlLabel value="6" control={<Radio size="sm" sx={{
                      '&.Mui-checked': {
                        color: getCor(6)
                      }
                    }} />} label={<Typography variant="subtitle2">Avaliada</Typography>} />

                    <FormControlLabel value="" control={<Radio size="sm" sx={{
                      '&.Mui-checked': {
                        color: '#000'
                      }
                    }} />} label={<Typography variant="subtitle2">Todas</Typography>} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Tipo de Serviço</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== ordemServico && ordemServico.length
                    ? ordemServico.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.tipoServico ? n.tipoServico.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.titulo ? n.titulo.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.orgao ? n.orgao.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Chip variant="outlined" sx={{
                              backgroundColor: getCor(n.ultimoEvento)
                            }}
                              label={getNomeUltimoEvento(n.ultimoEvento)} />
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'ordensServico.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/ordemServico/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'ordensServico.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
OrdemServico.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    ordemServico: state.ordemServico
  }
}
const connectedOrdemServicoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(OrdemServico))
)
export { connectedOrdemServicoPage as OrdemServico }
