import React from 'react'
import cx from 'clsx'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { Column, Item } from '@mui-treasury/components/flex'
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info'
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise'
import { Hidden } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    borderRadius: 16,
    padding: 12,
    backgroundColor: '#f8f7ef',
    minWidth: 300
  },
  learnMore: {
    backgroundColor: '#fed42c !important',
    color: '#575754',
    borderRadius: 20,
    minWidth: 120,
    minHeight: 42,
    textTransform: 'initial',
    fontSize: '0.875rem',
    fontWeight: 700,
    letterSpacing: 0
  },
  img: {
    position: 'absolute',
    width: '10%',
    bottom: '40%',
    right: '25%',
    display: 'block'
  },
  img2: {
    position: 'absolute',
    width: '20%',
    bottom: 0,
    right: 0,
    display: 'block'
  },
  shell: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(70%, 50%)',
    borderRadius: '50%',
    backgroundColor: 'rgba(167, 153, 71, 0.12)',
    padding: '40%',
    '&:before': {
      position: 'absolute',
      borderRadius: '50%',
      content: '""',
      display: 'block',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: '-16%',
      backgroundColor: 'rgba(167, 149, 71, 0.08)'
    }
  }
}))

const useOfferInfoStyles = makeStyles(() => {
  return {
    title: {
      color: '#545454',
      fontSize: '1.125rem',
      fontWeight: 700
    },
    subtitle: {
      color: '#7e7e7e',
      fontSize: '0.875rem',
      fontWeight: 500
    }
  }
})

export const PickbusBanner = React.memo(function OfferCard() {
  const styles = useStyles()
  const shadowStyles = useSoftRiseShadowStyles()
  return (
    <>
      <Card className={cx(styles.card, shadowStyles.root)}>
        <Column gap={2} mr={2}>
          <Info position={'middle'} useStyles={useOfferInfoStyles}>
            <InfoTitle>
              Que tal disponibilizar um transporte para seu evento?
            </InfoTitle>
            <InfoSubtitle>
              A ferramenta que veio para facilitar e agilizar a organização de
              transportes fretados!
            </InfoSubtitle>
          </Info>
          <Item mt={2}>
            <Button
              target="_blank"
              rel="noopener"
              className={styles.learnMore}
              href="https://pickbus.com.br/"
            >
              Conheça o Pickbus
            </Button>
          </Item>
        </Column>
        <Hidden smDown>
          <img
            className={styles.img}
            alt={''}
            src={'https://pickbus.com.br/static/media/logo_nome.4d1faa3f.png'}
          />
          <img
            className={styles.img2}
            alt={''}
            src={
              'https://pickbus.com.br/static/media/bus-stop-pana.8f66ac56.svg'
            }
          />
        </Hidden>
        <div className={styles.shell} />
      </Card>
    </>
  )
})
