const initialState = {
  isLoading: false,
  totalRegistros: 0,
  veiculo: [],
  open: false,
  id: 0,
  ano: '',
  placa: '',
  renavam: '',
  numeroChassis: '',
  hodometro: '',
  idOrgao: 0,
  idMarca: 0,
  modelo: '',
  caminhoFoto: '',
  usuariosVeiculos: [],
  excluido: false,
}

export function veiculo(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_VEICULO':
      return {
        ...state,
        veiculo: action.veiculo,
        totalRegistros: action.totalRegistros
      }
    case 'VEICULO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        ano: action.ano,
        placa: action.placa,
        renavam: action.renavam,
        hodometro: action.hodometro,
        numeroChassis: action.numeroChassis,
        idOrgao: action.idOrgao,
        idMarca: action.idMarca,
        modelo: action.modelo,
        caminhoFoto: action.caminhoFoto,
        usuariosVeiculos: action.usuariosVeiculos,
        excluido: action.excluido
      }
    case 'VEICULO_UPDATED':
      return state   
    case 'VEICULO_CLEAR':
      return initialState
    case 'VEICULO_CLEAR_ALL':
      return {
        ...state,
        veiculo: []
      }
    case 'VEICULO_CLEAR_USUARIOS_VEICULOS':
      return {
        ...state,
        usuariosVeiculos: []
      }
    case 'VEICULO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'VEICULO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'VEICULO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
