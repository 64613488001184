import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { usuarioAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info';
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';


const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  }
})

const Usuario = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { usuario } = props.usuario
  const { totalRegistros } = props.usuario

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction
      };

      dispatch(usuarioAction.getUsuario(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);
   //console.log("usuarios", usuario)
  }, [page, limit, debounceTerm, order, direction]);

  useEffect(() => {

  }, [props.usuario.usuario]);

  useEffect(() => {

  }, [totalRegistros]);

  const handleVerificaEmailUsuario = (n) => {
    confirm({
      title: 'Você deseja verificar este e-mail?',
      description: 'O status verificado será alterado para "SIM"',
      confirmationText: 'Sim, verificar!',
      disabled: props.usuario.isLoading,
      confirmationText: props.usuario.isLoading ? 'Verificando e-mail...' : 'Sim, verificar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(usuarioAction.verifyUsuarioEmail(n))
    })
  }

  const handleRequestPasswordReset = (email) => {
    dispatch(usuarioAction.requestPasswordReset(email))
  }

  useEffect(() => {

  }, [ready, props.usuario.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Usuário?',
      description: 'Essa operação é irreversível',
      disabled: props.usuario.isLoading,
      confirmationText: props.usuario.isLoading ? 'Excluindo usuário...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(usuarioAction.deleteUsuarioById(id))
    })
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Usuarios">
      <AccessControl
        rule={'usuarios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== usuario && usuario.length > 0 ? (
              <TitleContent
                rule={'usuarios.create'}
                length={usuario.length}
                subTitle={'GERÊNCIA DE USUÁRIO'}
                title={'Lista de Usuários'}
                href={'/usuario'}
              />
            ) : (
              <TitleContent
                rule={'usuarios.create'}
                length={0}
                subTitle={'GERÊNCIA DE USUÁRIO'}
                title={'Lista de Usuários'}
                href={'/usuario'}
              />
            )}
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={handleChange('term')}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Cadastro Ativo
                      <Tooltip
                        style={{ paddingLeft: 4 }}
                        title="Exibe se o usuário já criou uma senha de acesso ao sistema"
                      >
                        <InfoIcon size={9} sx={{ verticalAlign: 'middle' }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== usuario && usuario.length
                    ? usuario.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.nome ? n.nome.toUpperCase() : ''} {n.sobrenome ? n.sobrenome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.email ? n.email.toLowerCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.tipoUsuario ? n.tipoUsuario.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {(n.senha != null && n.senha != '') ? 'SIM' : 'NÃO'}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'usuarios.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/usuario/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'usuarios.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            {/*{n.emailVerificado != true &&*/}
                            {/*  <AccessControl*/}
                            {/*    rule={'usuariosEmail.verify'} //permissão necessária para acessar conteúdo*/}
                            {/*    yes={() => (*/}
                            {/*      <IconButton*/}
                            {/*        aria-label="VerifyEmail"*/}
                            {/*        size="small"*/}
                            {/*        onClick={() => handleVerificaEmailUsuario(n.id)}*/}
                            {/*      >*/}
                            {/*        <Tooltip title="Verificar E-mail">*/}
                            {/*          <DoneIcon />*/}
                            {/*        </Tooltip>*/}
                            {/*      </IconButton>*/}
                            {/*    )}*/}
                            {/*  />*/}
                            {/*}*/}
                            {n.senha == null || n.senha == '' &&
                              <AccessControl
                                rule={'usuariosEmailPassword.send'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="ReenviarEmailDefinicaoSenha"
                                    size="small"
                                    onClick={() => handleRequestPasswordReset(n.email)}
                                  >
                                    <Tooltip title="Reenviar E-mail De Definição de Senha">
                                      <SendIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Usuario.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    usuario: state.usuario
  }
}
const connectedUsuarioPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Usuario))
)
export { connectedUsuarioPage as Usuario }
