import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouteWithLayout } from './components'
import { PrivateRouteWithLayout } from './components'
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from './layouts'

import {
  Error404 as Error404View,
  EmailVerification as EmailVerificationView,
  Empresa as EmpresaView,
  EmpresaEdit as EmpresaEditView,
  Home as HomeView,
  Login as LoginView,
  Marca as MarcaView,
  MarcaEdit as MarcaEditView,
  OrdemServico as OrdemServicoView,
  OrdemServicoEdit as OrdemServicoEditView,
  Orgao as OrgaoView,
  OrgaoEdit as OrgaoEditView,
  Parametro as ParametroView,
  ParametroEdit as ParametroEditView,
  PasswordReset as PasswordResetView,
  PasswordResetSuccess as PasswordResetSuccessView,
  Dashboard as DashboardView,
  Usuario as UsuarioView,
  UsuarioEdit as UsuarioEditView,
  Veiculo as VeiculoView,
  VeiculoEdit as VeiculoEditView
} from './views'

const Routes = () => {
  return (
    <Switch>
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/"
      />
      <PrivateRouteWithLayout
        component={MarcaView}
        exact
        layout={MainLayout}
        path="/marcas"
      />
      <PrivateRouteWithLayout
        component={MarcaEditView}
        exact
        layout={MainLayout}
        path="/marca"
      />
      <PrivateRouteWithLayout
        component={MarcaEditView}
        exact
        layout={MainLayout}
        path="/marca/:id"
      />
      <PrivateRouteWithLayout
        component={UsuarioView}
        exact
        layout={MainLayout}
        path="/usuarios"
      />
      <PrivateRouteWithLayout
        component={UsuarioEditView}
        exact
        layout={MainLayout}
        path="/usuario"
      />
      <PrivateRouteWithLayout
        component={UsuarioEditView}
        exact
        layout={MainLayout}
        path="/usuario/:id"
      />
      <PrivateRouteWithLayout
        component={EmpresaView}
        exact
        layout={MainLayout}
        path="/empresas"
      />
      <PrivateRouteWithLayout
        component={EmpresaEditView}
        exact
        layout={MainLayout}
        path="/empresa"
      />
      <PrivateRouteWithLayout
        component={EmpresaEditView}
        exact
        layout={MainLayout}
        path="/empresa/:id"
      />
      <PrivateRouteWithLayout
        component={OrgaoView}
        exact
        layout={MainLayout}
        path="/orgaos"
      />
      <PrivateRouteWithLayout
        component={OrgaoEditView}
        exact
        layout={MainLayout}
        path="/orgao"
      />
      <PrivateRouteWithLayout
        component={OrgaoEditView}
        exact
        layout={MainLayout}
        path="/orgao/:id"
      />
      <PrivateRouteWithLayout
        component={ParametroView}
        exact
        layout={MainLayout}
        path="/parametros"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainLayout}
        path="/parametro"
      />
      <PrivateRouteWithLayout
        component={ParametroEditView}
        exact
        layout={MainLayout}
        path="/parametro/:id"
      />
      <PrivateRouteWithLayout
        component={VeiculoView}
        exact
        layout={MainLayout}
        path="/veiculos"
      />
      <PrivateRouteWithLayout
        component={VeiculoEditView}
        exact
        layout={MainLayout}
        path="/veiculo"
      />
      <PrivateRouteWithLayout
        component={VeiculoEditView}
        exact
        layout={MainLayout}
        path="/veiculo/:id"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoView}
        exact
        layout={MainLayout}
        path="/ordensServico"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoEditView}
        exact
        layout={MainLayout}
        path="/ordemServico"
      />
      <PrivateRouteWithLayout
        component={OrdemServicoEditView}
        exact
        layout={MainLayout}
        path="/ordemServico/:id"
      />
      <Route component={LoginView} exact path="/login" />
      <Route
        component={EmailVerificationView}
        exact
        path="/email-verification"
      />
      <Route
        component={PasswordResetView}
        exact
        path="/password-reset"
      />
      <Route
        component={PasswordResetSuccessView}
        exact
        path="/password-reset-success"
      />
      <RouteWithLayout
        component={Error404View}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
