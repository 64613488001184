import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const parametroAction = {
  getParametro,
  addParametro,
  getParametroById,
  onChangeProps,
  onChangePropsHtml,
  editParametroInfo,
  editParametrosDetails,
  createParametro,
  deleteParametroById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getParametro(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'parametros' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeParametrosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createParametro(payload) {
  return (dispatch) => {
    let apiEndpoint = 'parametros/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createParametroInfo())
      dispatch(clear())
      history.push('/parametros')
    })
  }
}

function getParametroById(id) {
  return (dispatch) => {
    let apiEndpoint = 'parametros/' + id
    crudService.get(apiEndpoint).then((response) => {
      //  console.log('response.data', response.data)
      dispatch(editParametrosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function onChangePropsHtml(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event))
  }
}

function editParametroInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'parametros/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedParametroInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/parametros')
          }
        } else {
          toast.err('Oops! Erro ao editar parâmetro! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao editar parâmetro! 😥')
        return err.response;
      })
  }
}

function deleteParametroById(id) {
  return (dispatch) => {
    let apiEndpoint = 'parametros/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteParametrosDetails())
      dispatch(getParametro(filterModel))
    })
  }
}

export function addParametro(payload) {
  return [{ type: 'ADD_PARAMETRO', parametro: payload }, clear()]
}

export function changeParametrosList(parametro, totalRows) {
  return {
    type: 'FETCHED_ALL_PARAMETRO',
    parametro: parametro,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'PARAMETRO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'PARAMETRO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'PARAMETRO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editParametrosDetails(parametro) {
  return [
    { type: 'PARAMETRO_DETAIL', parametro },
  ]
}

export function updatedParametroInfo() {
  return {
    type: 'PARAMETRO_UPDATED'
  }
}

export function createParametroInfo() {
  return {
    type: 'PARAMETRO_CREATED_SUCCESSFULLY'
  }
}

export function deleteParametrosDetails() {
  return {
    type: 'DELETED_PARAMETRO_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'PARAMETRO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'PARAMETRO_NOTISLOADING'
  }
}
