const initialState = {
  isLoading: false,
  totalRegistros: 0,
  marca: [],
  open: false,
  id: 0,
  nome: '',
  excluido: false,
}

export function marca(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_MARCA':
      return {
        ...state,
        marca: action.marca,
        totalRegistros: action.totalRegistros
      }
    case 'MARCA_DETAIL':
      return action.marca
    case 'MARCA_UPDATED':
      return state
    case 'MARCA_CLEAR':
      return initialState
    case 'MARCA_CLEAR_ALL':
      return {
        ...state,
        marca: []
      }
    case 'MARCA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'MARCA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'MARCA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
