const initialState = {
  anchor: 'left',
  empresa: [],
  open: false,
  id: 0,
  razaoSocial: '',
  nomeFantasia: '',
  cnpj: '',
  telefone: '',
  celular: '',
  email: '',
  endereco: '',
  idCidade: 0,
  idEstado: 0,
  inscricaoEstadual: '',
  usuariosEmpresas: [],
  empresasOrgaos: [],
  totalRegistros: 0,
  fornecedor: false,
  prestadorServico: false,
  cadastroLiberado: false
}

export function empresa(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_EMPRESA':
      return {
        ...state,
        empresa: action.empresa
      }
    case 'EMPRESA_DETAIL':
      return {
        ...state,
        id: action.id,
        razaoSocial: action.razaoSocial,
        nomeFantasia: action.nomeFantasia,
        cnpj: action.cnpj,
        telefone: action.telefone,
        celular: action.celular,
        email: action.email,
        endereco: action.endereco,
        idCidade: action.idCidade,
        idEstado: action.idEstado,
        inscricaoEstadual: action.inscricaoEstadual,
        usuariosEmpresas: action.usuariosEmpresas,
        empresasOrgaos: action.empresasOrgaos,
        fornecedor: action.fornecedor,
        prestadorServico: action.prestadorServico,
        cadastroLiberado: action.cadastroLiberado
      }
    case 'EMPRESA_UPDATED':
      return state
    case 'EMPRESA_CLEAR':
      return initialState
    case 'EMPRESA_CLEAR_ALL':
      return {
        ...state,
        empresa: []
      }
    case 'EMPRESA_CLEAR_USUARIOS_EMPRESAS':
      return {
        ...state,
        usuariosEmpresas: []
      }
    case 'FETCHED_ALL_EMPRESA':
      return {
        ...state,
        empresa: action.empresa,
        totalRegistros: action.totalRegistros
      }
    case 'EMPRESA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
