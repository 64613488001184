const initialState = {
  totalRegistros: 0,
  isLoading: false,
  orgao: [],
  id: '',
  nome: '',
  idCidade: 0,
  idEstado: '',
  excluido: '',
  celular: '',
  cnpj: '',
  email: '',
  endereco: '',
  telefone: '',
  cadastroLiberado: false,
  usuariosOrgaos: []
}

export function orgao(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ORGAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_ORGAO_SUCCESS':
      return {
        ...state,
        id: action.orgao.id,
        isLoading: false,
        nome: action.orgao.nome,
        celular: action.orgao.celular,
        cnpj: action.orgao.cnpj,
        email: action.orgao.email,
        endereco: action.orgao.endereco,
        telefone: action.orgao.telefone,
        idCidade: action.orgao.idCidade,
        idEstado: action.orgao.idEstado,
        cadastroLiberado: action.orgao.cadastroLiberado,
        usuariosOrgaos: action.usuariosOrgaos
      }
    case 'CREATE_ORGAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_ORGAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_ORGAO_SUCCESS':
      return {
        ...state,
        id: action.orgao.id,
        isLoading: false,
        nome: action.orgao.nome,
        celular: action.orgao.celular,
        cnpj: action.orgao.cnpj,
        email: action.orgao.email,
        endereco: action.orgao.endereco,
        telefone: action.orgao.telefone,
        idCidade: action.orgao.idCidade,
        idEstado: action.orgao.idEstado,
        cadastroLiberado: action.orgao.cadastroLiberado,
        usuariosOrgaos: action.orgao.usuariosOrgaos
      }
    case 'EDIT_ORGAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_ORGAO':
      return {
        ...state,
        orgao: action.orgao,
        totalRegistros: action.totalRegistros
      }
    case 'ORGAO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        nome: action.nome,
        celular: action.celular,
        cnpj: action.cnpj,
        email: action.email,
        endereco: action.endereco,
        telefone: action.telefone,
        idCidade: action.idCidade,
        idEstado: action.idEstado,
        cadastroLiberado: action.cadastroLiberado,
        usuariosOrgaos: action.usuariosOrgaos
      }
    case 'ORGAO_CLEAR':
      return initialState
    case 'ORGAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ORGAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ORGAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
