import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Divider, Drawer } from '@mui/material'
import { withStyles } from '@mui/styles'
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';

import { authActions } from '../../../../_actions'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Profile, SidebarNav } from './components'

const styles = (theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  sidebarNav: {
    paddingTop: 0,
    alignContent: 'right',
    justifyContent: 'end'
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  nav: {
    marginBottom: theme.spacing(1)
  }
})

const Sidebar = ({ staticContext, ...props }) => {

  const { open, variant, onClose, className, ...rest } = props
  const dispatch = useDispatch()
  const { user } = props
  const [isClickedOptionSubmenu, setIsClickedOptionSubMenu] = useState(false);

  const logout = () => {
    dispatch(authActions.logout())
  }
  const { classes } = props

  const isDisabled = useSelector((state) => {
    return false
  })

  const pages = [
    {
      title: 'Home',
      href: '/dashboard',
      icon: <HomeTwoToneIcon />
    },
    {
      disabled: isDisabled,
      clickedSub: isClickedOptionSubmenu,
      title: 'Usuários',
      href: '/usuarios',
      rule: 'usuarios.list',
      onClick: () => {
        setIsClickedOptionSubMenu(true)
      },
      icon: <PeopleOutlineTwoToneIcon />
    }
  ]

  const pagesSair = [
    {
      title: 'Sair',
      href: '/login',
      icon: <ExitToAppTwoToneIcon />,
      onClick: () => {
        logout()
      }
    }
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <PerfectScrollbar>
        <div {...rest} className={clsx(classes.root, className)}>
          <Profile user={user} />
          <Divider className={classes.divider} />
          <SidebarNav pages={pages} className={classes.sidebarNav} />
          <Divider className={classes.divider} />
          <SidebarNav pages={pagesSair} />
        </div>
      </PerfectScrollbar>
    </Drawer>
  )
}


Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user
  }
}
const connectedSidebarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Sidebar))
)
export { connectedSidebarPage as Sidebar }
