import React, { useLayoutEffect } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ConfirmProvider } from 'material-ui-confirm';
import './App.css';
import theme from './theme';
import Routes from './Routes';
import Mensagens from './components/Utils';
import { authActions } from './_actions';
import { useDispatch } from 'react-redux';
import { history } from './utils';

const App = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(authActions.refresh())
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <div className="App">
            <Mensagens />
          </div>
          <Router history={history} >
            <Routes />
          </Router>
        </ConfirmProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}
export default App
