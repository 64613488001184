import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const ordemServicoAction = {
  getOrdemServico,
  getOrdemServicoSelect,
  getOrdemServicoById,
  onChangeProps,
  editOrdemServicoInfo,
  editOrdemServicoAprova,
  editOrdemServicoAprovaOrcamento,
  editOrdemServicoRecusa,
  editOrdemServicoRecusaOrcamento,
  createOrdemServico,
  deleteOrdemServicoById,
  clear,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getOrdemServico(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeOrdemServicosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrdemServicoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'ordensServico/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeOrdemServicosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createOrdemServico(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServico'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          // toast.error('Oops! Erro ao cadastrar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar ordem de serviço! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_ORDEMSERVICO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_ORDEMSERVICO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'ORDEMSERVICO_CLEAR'
  }
}

function editOrdemServicoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServico/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoAprova(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/aprova/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoRecusa(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }
}

function editOrdemServicoAprovaOrcamento(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/aprova/orcamento/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function editOrdemServicoRecusaOrcamento(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/recusa/orcamento/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/ordensServico')
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
  }
}

function request(payload) {
  return { type: 'EDIT_ORDEMSERVICO_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_ORDEMSERVICO_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_ORDEMSERVICO_FAILURE', error }
}


function getOrdemServicoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'ordensServico/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editOrdemServicosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteOrdemServicoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServico/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteOrdemServicosDetails())
      dispatch(notIsLoading())
      history.push('/ordensServico')
      dispatch(getOrdemServico(filterModel))
    })
  }
}
export function changeOrdemServicosList(ordemServico, totalRows) {
  return {
    type: 'FETCHED_ALL_ORDEMSERVICO',
    ordemServico: ordemServico,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'ORDEMSERVICO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editOrdemServicosDetails(ordemServico) {
  return {
    type: 'ORDEMSERVICO_DETAIL',
    id: ordemServico.id,
    idTipoServico: ordemServico.idTipoServico,
    idVeiculo: ordemServico.idVeiculo,
    descritivo: ordemServico.descritivo,
    titulo: ordemServico.titulo,
    idUsuario: ordemServico.idUsuario,
    data: ordemServico.data,
    avaliacao: ordemServico.avaliacao,
    descritivoAvaliacao: ordemServico.descritivoAvaliacao,
    idUsuarioAvaliacao: ordemServico.idUsuarioAvaliacao,
    idOrgao: ordemServico.idOrgao,
    idEmpresa: ordemServico.idEmpresa,
    ultimoEvento: ordemServico.ultimoEvento,
    empresa: ordemServico.empresa,
    eventos: ordemServico.eventos,
    ordensServicosFotos: ordemServico.ordensServicosFotos,
    ordensServicosOrcamentos: ordemServico.ordensServicosOrcamentos
  }
}
export function createUserInfo() {
  return {
    type: 'ORDEMSERVICO_CREATED_SUCCESSFULLY'
  }
}
export function deleteOrdemServicosDetails() {
  return {
    type: 'DELETED_ORDEMSERVICO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'ORDEMSERVICO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'ORDEMSERVICO_NOTISLOADING'
  }
}
