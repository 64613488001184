import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Typography, Grid, Button, Hidden } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import messageTime from 'utils/stringTimeHello'
import { history } from 'utils'
import Lottie from 'react-lottie';
import animationData from './rocket.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const useStyles = makeStyles((theme) => ({
  root: {},
  summaryButton: {
    backgroundColor: theme.palette.white
  },
  barChartIcon: {
    marginRight: theme.spacing(1)
  }
}))

const Header = (props) => {
  const { className, ...rest } = props
  const user = useSelector((state) => state.authentication.user)
  const isDisabled = useSelector((state) => {
    //if (
    //  state.usuarioLoja.usuarioLoja.length <= 0
    //) {
    //  return true
    //}
    return false
  })
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Página inicial
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            {messageTime()}, {user.nome.split(' ').slice(0, -1).join(' ')}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            Confira a performance da loja nos últimos dias
          </Typography>
          <Button
            className={classes.summaryButton}
            edge="start"
            variant="contained"
            disabled={isDisabled}
            onClick={() => history.push('/dashboard')}
          >
            <BarChartIcon className={classes.barChartIcon} />
            Ver dashboard
          </Button>
        </Grid>
        <Hidden smDown>
          <Grid item md={6}>
            {/*<Image
              src="/images/home.svg"
              aspectRatio={16 / 9}
              color="transparent"
              disableTransition
            />*/}
            <Lottie
              options={defaultOptions}
              height={250}
              width={250}
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header
