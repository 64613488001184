import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    paddingTop: 10,
    paddingBottom: 30
  },
  name: {
    marginTop: theme.spacing(1)
  }
}))

const Profile = (props) => {
  const { user, className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>     
      <Typography align="center" className={classes.name} variant="h4">
        {user.nome}
      </Typography>
      <Typography noWrap variant="body1">
        {user.email}
      </Typography>
      <Typography variant="body2">{user.tipoUsuario.descricao}</Typography>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string
}

export default Profile
