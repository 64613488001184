import React, { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import {
  List,
  ListItem,
  Button,
  Tooltip,
  Divider
} from '@mui/material'
import { AccessControl } from '../../../../../../components/Utils/AccessControl'
import { history } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    color: '#37474f',
  },
  button: {
    color: '#37474f',
    padding: '8px 5px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {   
    color: '#37474f',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  }, 
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  tooltipText: {
    textAlign: 'center'
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props
  const classes = useStyles()

   return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page, index) => (
        <React.Fragment key={index}>
          {page.rule !== undefined ? (
            <AccessControl
              rule={page.rule}
              yes={() => (
                <Tooltip
                  title={
                    <span className={classes.tooltipText}>
                      <p>Função bloqueada 🤔 </p>
                      <p>Clique para completar seu cadastro!</p>
                    </span>
                  }
                  arrow
                  onClick={() =>
                    page.disabled &&
                    history.push(`/estabelecimento/`)
                  }
                  disableFocusListener={!page.disabled}
                  disableHoverListener={!page.disabled}
                  disableTouchListener={!page.disabled}
                >
                  { page.clicked == true ? (
                    <ListItem
                      style={{ cursor: 'not-allowed' }}
                      disabled={page.disabled}
                      activeClassName={classes.active}
                      className={classes.item}
                      clicked={page.clicked}
                      disableGutters
                    >
                      <Button
                        disabled={page.disabled}
                        className={classes.button}
                        activeClassName={classes.active}
                        component={CustomRouterLink}
                        to={page.href}
                        onClick={page.onClick}
                      >
                        <div className={classes.icon}>{page.icon}</div>
                        {page.title}
                      </Button>
                    </ListItem>
                    ) : (
                    <ListItem
                      style={{ cursor: 'not-allowed' }}
                      disabled={page.disabled}
                      className={classes.item}
                      clicked={page.clicked}
                      disableGutters
                    >
                        {page.clickedSub == true ?
                          <Button
                           disabled={page.disabled}
                           activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={page.href}
                        onClick={page.onClick}
                          >
                            <div className={classes.icon}>{page.icon}</div>
                            {page.title}
                          </Button>
                            :
                            <Button
                              disabled={page.disabled}
                              className={classes.button}
                              component={CustomRouterLink}
                              to={page.href}
                              onClick={page.onClick}
                            >
                             
                        <div className={classes.icon}>{page.icon}</div>
                        {page.title}
                            </Button>
                            }
                    </ListItem>
                  ) }
                </Tooltip>
              )}             
            />
          ) : (
            <Tooltip
              title={
                <span className={classes.tooltipText}>
                  <p>Função bloqueada 🤔 </p>
                  <p>Clique para completar seu cadastro!</p>
                </span>
              }
              arrow
              onClick={() =>
                page.disabled &&
                history.push(`/estabelecimento/`)
              }
              disableFocusListener={!page.disabled}
              disableHoverListener={!page.disabled}
              disableTouchListener={!page.disabled}
            >
              <ListItem
                style={{ cursor: 'not-allowed' }}
                disabled={page.disabled}
                className={classes.item}
                disableGutters
              >
                <Button
                  disabled={page.disabled}
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}
                  onClick={page.onClick}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              </ListItem>
            </Tooltip>
          )}
          {page.divider && <Divider className={classes.divider} />}
        </React.Fragment>
      ))}
    </List>
  )
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
}

export default SidebarNav
