import { authentication } from './auth.reducer'
import { cidade } from './cidade.reducer'
import { dashboard } from './dashboard.reducer'
import { estado } from './estado.reducer'
import { empresa } from './empresa.reducer'
import { empresaOrgao } from './empresaOrgao.reducer'
import { marca } from './marca.reducer'
import { orgao } from './orgao.reducer'
import { ordemServico } from './ordemServico.reducer'
import { ordemServicoFoto } from './ordemServicoFoto.reducer'
import { parametro } from './parametro.reducer'
import { permissao } from './permissao.reducer'
import { permissaoTipoUsuario } from './permissaoTipoUsuario.reducer'
import { permissaoUsuario } from './permissaoUsuario.reducer'
import { tipoFoto } from './tipoFoto.reducer'
import { tipoUsuario } from './tipoUsuario.reducer'
import { tipoServico } from './tipoServico.reducer'
import { usuario } from './usuario.reducer'
import { usuarioOrgao } from './usuarioOrgao.reducer'
import { usuarioEmpresa } from './usuarioEmpresa.reducer'
import { veiculo } from './veiculo.reducer'

import { combineReducers } from 'redux'

import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
  authentication,
  cidade,
  dashboard,
  estado,
  empresa,
  empresaOrgao,
  marca,
  ordemServico,
  ordemServicoFoto,
  orgao,
  parametro,
  permissao,
  permissaoTipoUsuario,
  permissaoUsuario,
  tipoServico,
  tipoUsuario,
  tipoFoto,
  usuario,
  usuarioOrgao,
  usuarioEmpresa,
  veiculo,
  form: formReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    const { routing } = state
    state = { routing }
  }
  return appReducer(state, action)
}
export default rootReducer
