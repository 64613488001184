import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Grid,
  MenuItem
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { usuarioAction, tipoUsuarioAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { phoneMask, history, stringUtils } from '../../utils'
import { Page } from 'components'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const UsuarioEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, className, idorgao, isorgao, toggle, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const tipoUsuario = useSelector((state) => state.tipoUsuario.tipoUsuario)

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    dispatch(tipoUsuarioAction.getTiposUsuarioSelect())

    if (params.id && (idorgao == undefined || idorgao == null)) {
      dispatch(usuarioAction.getUsuarioById(params.id))
    } else {
      dispatch(usuarioAction.clear())
    }

  }, []);

  const { idUsuario } = useSelector(
    (state) => state.usuario
  )

  useEffect(() => {

  }, [ready, props.usuario.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(usuarioAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.usuario.nome,
      senha: props.usuario.senha,
      sobrenome: props.usuario.sobrenome,
      email: props.usuario.email.toLowerCase(),
      idTipoUsuario: props.usuario.idTipoUsuario,
      caminhoCnh: props.usuario.caminhoCnh,
      celular: props.usuario.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''), 
      cnh: props.usuario.cnh,
      matricula: props.usuario.matricula,
      telefone: props.usuario.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', '')
    }
    if (isorgao != true) {
      if (params.id) {
        dispatch(usuarioAction.editUsuarioInfo(params.id, payload, true))
      } else {
        dispatch(usuarioAction.createUsuario(payload))
      }
    } else {
      handleAddOnList()
    }
  };

  const handleAddOnList = () => {
    if (props.orgao.usuariosOrgaos.filter(ts => ts.idUsuario == parseInt(idUsuario)).length > 0) {
      toast.error('Erro ao adicionar! Este usuário já existe!')
    }
    else {
      props.orgao.usuariosOrgaos.push({
        'idUsuario': params.id != 0 ? params.id : props.usuario.idUsuarioOrgao,
        'idOrgao': idorgao ? idorgao : 0,
        'usuario': {
          'nome': props.usuario.nome,
          'sobrenome': props.usuario.sobrenome,
          'email': props.usuario.email.toLowerCase(),
          'idTipoUsuario': props.usuario.idTipoUsuario,
          'caminhoCnh': props.usuario.caminhoCnh,
          'celular': props.usuario.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''), 
          'cnh': props.usuario.cnh,
          'matricula': props.usuario.matricula,
          'telefone': props.usuario.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', '')
        }
      });

      forceUpdate(n => !n);
      toggle();
    }
  }

  const handleActions = () => {
    if (isorgao == true) {
      history.push('/orgao/' + idorgao);
    } else {
      history.push('/orgao/');
    }

    toggle();
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Usuário'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Usuário'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id && isorgao == false) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Usuários - Editar Usuário'
          : 'Gerência de Usuários - Novo Usuário'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formUsuario"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Usuários"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.usuario.nome || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="sobrenome"
                        label="Sobrenome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('sobrenome')}
                        value={props.usuario.sobrenome || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={12} md={6} xl={7} xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="E-mail *"
                        value={props.usuario.email || ''}
                        className={classes.textField}
                        onChange={handleChange('email')}
                        id="email"
                        name="email"
                        autoComplete="email"
                        validators={['required', 'isEmail']}
                        errorMessages={[
                          'Você precisa informar o e-mail',
                          'E-mail inválido'
                        ]}
                        disabled={props.usuario.id != 0}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={5} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idTipoUsuario"
                        label="Tipo Usuário *"
                        margin="normal"
                        value={props.usuario.idTipoUsuario}
                        onChange={handleChange('idTipoUsuario')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idTipoUsuario"
                      >
                        <MenuItem disabled value="">
                          <em>Tipo Usuário</em>
                        </MenuItem>
                        {!isEmpty(tipoUsuario) &&
                          undefined !== tipoUsuario &&
                          tipoUsuario.length &&
                          tipoUsuario.map((row) => (
                            <MenuItem key={row.value} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="celular"
                        label="Celular "
                        margin="normal"
                        onChange={handleChange('celular')}
                        value={phoneMask(props.usuario.celular) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="telefone"
                        label="Telefone "
                        margin="normal"
                        onChange={handleChange('telefone')}
                        value={phoneMask(props.usuario.telefone) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cnh"
                        label="CNH"
                        margin="normal"
                        onChange={handleChange('cnh')}
                        value={props.usuario.cnh || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="caminhoCnh"
                        label="Caminho CNH"
                        margin="normal"
                        onChange={handleChange('caminhoCnh')}
                        value={props.usuario.caminhoCnh || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="matricula"
                        label="Matrícula"
                        type="number"
                        margin="normal"
                        onChange={handleChange('matricula')}
                        value={props.usuario.matricula || ''}
                        variant="outlined"
                      />
                    </Grid>
                    {(props.usuario && props.usuario.dataHoraUltimoAcesso ?
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="dataHoraUltimoAcesso"
                          label="Data/Hora do Último Acesso"
                          margin="normal"
                          value={props.usuario.dataHoraUltimoAcesso ? moment(props.usuario.dataHoraUltimoAcesso).format("DD/MM/YYYY HH:mm") : ''}
                          variant="outlined"
                        />
                      </Grid>
                      :
                      null
                    )}
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    {(idorgao) ?
                      <Button
                        variant="outlined"
                        style={{ color: '#000', borderColor: '#000' }}
                        onClick={() => handleActions()}
                      >
                        Cancelar
                      </Button>
                      :
                      <Button
                        variant="outlined"
                        style={{ color: '#000', borderColor: '#000' }}
                        onClick={() => history.push('/Usuarios')}
                      >
                        Cancelar
                      </Button>
                    }
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: '#000' }}
                      disabled={props.usuario.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.usuario.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id && isorgao != true ? (props.usuario.isLoading ? 'Atualizando usuário...' : 'Atualizar') : (props.usuario.isLoading ? 'Salvando usuário...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page >
  )
}

UsuarioEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  idorgao: PropTypes.string,
  toggle: PropTypes.object,
  idUsuario: PropTypes.object,
  isorgao: PropTypes.bool
}
const mapStateToProps = (state) => {
  return state
}

const connectedUsuarioEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(UsuarioEdit))
)
export { connectedUsuarioEditPage as UsuarioEdit }
